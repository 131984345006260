import React, { ReactElement } from 'react';
import slide1 from 'assets/images/Slide1.jpg';
import slide2 from 'assets/images/Slide2.jpg';
import slide3 from 'assets/images/Slide3.jpg';
import AliceCarousel from 'react-alice-carousel';
import useTranslation, { getActiveLocale } from 'localizer';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useLocation } from 'react-router-dom';
import hotelBannerEN from 'assets/images/Hotel-banner-Sep-En.jpg';
import hotelBannerDE from 'assets/images/Hotel-banner-Sep-DE.jpg';

const AliceCarouselReact = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);

  return (
    <div className="wrapper-horizontal lasnding-wrapper-header mt-20 landing-carousel">
      <AliceCarousel
        disableDotsControls
        infinite
        renderPrevButton={() => (
          <svg width="63" height="63" viewBox="0 0 63 63">
            <defs>
              <filter
                id="Ellipse_247"
                x="0"
                y="0"
                width="63"
                height="63"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g id="Angle-left" transform="translate(-1206.256 -503.28)">
              <g
                transform="matrix(1, 0, 0, 1, 1206.26, 503.28)"
                filter="url(#Ellipse_247)"
              >
                <circle
                  id="Ellipse_247-2"
                  data-name="Ellipse 247"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(9 6)"
                  fill="#fff"
                  opacity="0.77"
                />
              </g>
              <path
                id="Path_24110"
                data-name="Path 24110"
                d="M5879.1-1925.755l-8.047,7.971,8.047,7.664"
                transform="translate(-4638.141 2449.927)"
                fill="none"
                stroke="#6f6f6f"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        )}
        renderNextButton={() => (
          <svg width="63" height="63" viewBox="0 0 63 63">
            <defs>
              <filter
                id="Ellipse_248"
                x="0"
                y="0"
                width="63"
                height="63"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g id="Angle-right" transform="translate(-1251.213 -503.936)">
              <g
                transform="matrix(1, 0, 0, 1, 1251.21, 503.94)"
                filter="url(#Ellipse_248)"
              >
                <circle
                  id="Ellipse_248-2"
                  data-name="Ellipse 248"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(9 6)"
                  fill="#fff"
                  opacity="0.77"
                />
              </g>
              <path
                id="Path_24109"
                data-name="Path 24109"
                d="M5871.053-1925.755l8.047,7.971-8.047,7.664"
                transform="translate(-4590.141 2449.927)"
                fill="none"
                stroke="#6f6f6f"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        )}
      >
        <div className="rounded-md overflow-hidden">
          <img
            src={locale === 'en' ? hotelBannerEN : hotelBannerDE}
            alt="banner"
            className="relative rounded-md"
          />

          <div className="month-banner-slide-box">
            <div className="month-banner-first-text">
              <div>
                {t(
                  'Luxurious Half Board, Exclusive Wellness, Aperitif, Cocktail Tasting, Late Check-Out.',
                )}
              </div>
            </div>

            <div className="month-banner-second-text">
              {t('Romantik Hotel Kleber Post:')}
            </div>
            <div className="month-banner-third-text">{t('30% Off!')}</div>

            <div className="flex month-banner-btn-margin-top">
              <a
                className="month-banner-btn"
                href={
                  locale === 'en'
                    ? 'https://www.greenhabitat.at/en/hotels/romantik-hotel-kleber-post'
                    : 'https://www.greenhabitat.at/hotels/romantik-hotel-kleber-post'
                }
              >
                {t('Book Now!')}
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-md overflow-hidden">
          <img src={slide1} alt="slide1" className="rounded-md" />
          <div className="text-block">
            <div className="text-slider">
              {t(
                'Save up to 50% on your holiday! With Green Habitat, you would travel to the most beautiful regions across Europe.',
              )}
            </div>
          </div>
        </div>
        <div className="rounded-md overflow-hidden">
          <img src={slide2} alt="slide2" className="rounded-md" />
          <div className="text-block">
            <div
              className="text-slider"
              dangerouslySetInnerHTML={{
                __html: t(
                  'Nature is close to our hearts. Our hotels are awarded green leaves for their environmentally friendly commitment. Learn more about sustainable vacations with Green Habitat.',
                ),
              }}
            />
          </div>
        </div>
        <div className="rounded-md overflow-hidden">
          <img src={slide3} alt="slide3" className="rounded-md" />
          <div className="text-block">
            <div className="text-slider">
              {t(
                'Thinking globally about environmental protection. Together with our partner TwoWings, we support agricultural projects in Colombia.',
              )}
            </div>
            <div className="mt-3">
              <a
                target="__blank"
                href={
                  locale === 'en'
                    ? 'https://www.twowings.com/en/programme/fundaec'
                    : 'https://www.twowings.com/programme/fundaec'
                }
              >
                <div className="solution-btn">{t('Learn More')}</div>
              </a>
            </div>
          </div>
        </div>
      </AliceCarousel>
    </div>
  );
};

export default AliceCarouselReact;
