import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ImageCardWrapper = styled.div`
  > a {
    display: block;
    height: 370px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const Title = styled.h4`
  color: #2c2c2c;
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
  opacity: 1;
  :hover {
    color: #a77f34;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BackgroundTitle = styled.div`
  background-color: #fffbf5;
  padding: 10px;
  border-radius: 5px;
  opacity: 0.81;
`;

export const Meta = styled.div`
  color: ${themeGet('color.1', '#ffffff')};
  font-size: 17px;
  line-height: 21px;
  font-weight: 400;
  text-transform: capitalize;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const CountryName = styled.h6`
  color: #989898;
  font-size: 12px;
  line-height: 30px;
  text-transform: capitalize;
  text-align: left;
  opacity: 1;
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export default ImageCardWrapper;
