import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet('primary.0', '#008489')};
    color: ${themeGet('color.1', '#ffffff')};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  :root {
    --custom_green: #039536;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: raleway;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: raleway;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  
  input,
  textarea,
  select,
  .ant-select,
  .ant-select-item,
  .guestPopup button span {
    font-size: 14px !important;
  }
  
  button, a, html [type="button"] {
  -webkit-appearance: button-bevel !important;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet('color.1', '#ffffff')};

    main.ant-layout-content {
      flex: 1 0 auto;
     // padding-right: 65px;
     // padding-left: 65px;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet('text.0', '#2C2C2C')};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet('text.2', '#777777')};
        justify-content: flex-start;
        strong {
          color: ${themeGet('text.0', '#2C2C2C')};
          width: 30%;
        }
      }
    }
  }

  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#2C2C2C')};
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#008489')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            svg {
              width: 14px !important;
              height: 14px;
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('color.2', '#F7F7F7')};
          }
        }
      }
    }
  }

  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 99999 !important;
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 5px !important;
    width: 200px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media only screen and (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media only screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media only screen and (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet('color.1', '#ffffff')};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    min-width: 104px;
    
    button.btn {
      border: 1px solid ${themeGet('primary.0', '#008489')};
      svg path {
        fill: ${themeGet('primary.0', '#008489')};
      }
      &:hover {
        background-color: ${themeGet('primary.0', '#008489')};
        svg path {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }

  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        margin: 0 0 30px;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet('color.2', '#F7F7F7')};
        li {
          height: auto;
          line-height: 1;
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 700;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          &:last-child {
            a {
              color: ${themeGet('color.1', '#ffffff')};
              background-color: ${themeGet('primary.0', '#008489')};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .main-menu {
        border: 0;
        padding-top: 30px;
        li {
          padding: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            font-size: 15px;
            padding: 0 31px;
            border-left: 4px solid transparent;
            color: ${themeGet('text.0', '#2C2C2C')};
            &.active {
              font-weight: 700;
              border-color: ${themeGet('primary.0', '#008489')};
              color: ${themeGet('primary.0', '#008489')};
            }
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 32px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet('text.0', '#2C2C2C')};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
              color: ${themeGet('primary.0', '#008489')};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet('primary.0', '#008489')};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet('text.1', '#909090')};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet('text.0', '#2C2C2C')};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet('color.0', '#000000')};
        }
      } 
      &:hover {
        background-color: ${themeGet('color.2', '#F7F7F7')};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 25px;
            padding-bottom: 20px;
            border-color: ${themeGet('border.0', '#EBEBEB')};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          header.pt-0{
            padding-top: 0px;
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet('border.0', '#EBEBEB')};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet('text.1', '#909090')};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .reservation_sidebar {
    background-color: ${themeGet('color.1', '#ffffff')};
    header {
      margin-bottom: 15px;
      padding: 0px 8px 12px 8px;
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
          color: ${themeGet('primary.0', '#008489')};
      }
    }

    p {
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet('primary.0', '#008489')};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color:${themeGet('primary.2')};
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
  .reservation_sidebar_without_detail {
    background-color: ${themeGet('color.1', '#ffffff')};
    header {
      border-bottom: 0px;
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
          color: ${themeGet('primary.0', '#008489')};
      }
    }
  }
  .grid_column .placeholder {
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 3px;
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }

  .ant-input-search-icon::before {
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }
  
  .ant-input:focus, .ant-picker.ant-picker-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    -webkit-box-shadow:  0 0 0 1px rgba(174,130,41,0.2) !important;
    box-shadow: 0 0 0 1px rgba(174,130,41,0.2) !important;
    border-color: rgb(237 216 176) !important;
  }
  .ant-input-affix-wrapper>input.ant-input:focus{
    -webkit-box-shadow:  none !important;
    box-shadow: none !important;
    border-color: none !important;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${themeGet('primary.0', '#008489')};
        background-color: ${themeGet('primary.0', '#008489')};
        &::after {
          transform: rotate(45deg) scale(1) translate(-50%, -70%);
        }
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${themeGet('primary.0', '#008489')} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 132, 137, 0.2);
  }

  .submit-btn {
    text-transform: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 44px;
   // width: 153px;
    font-size: 17px;
    font-weight: 700;
    border-color: ${themeGet('primary.0', '#008489')};
    background-color: ${themeGet('primary.0', '#008489')};
    svg {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
    &::after {
      content: none;
    }
    &:hover,
    &:focus {
      opacity: 0.85;
      border-color: ${themeGet('primary.0', '#008489')};
    background-color: ${themeGet('primary.0', '#008489')};
    }
    &:disabled {
      background: #e0e0e0 !important;
      border-color: #d9d9d9 !important;
    }
  }
  .gm-style-iw button[title="Close"].gm-ui-hover-effect{
    top:0 !important;
    box-shadow:0 0px 0px rgba(0,0,0,0.16);
    right: 0px !important;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .overview p {margin: 15px 0;} 
  
  .golden-bg.ant-btn-primary, .golden-bg.ant-btn-primary:hover, .golden-bg.ant-btn-primary:focus {
    background: ${themeGet('primary.0', '#008489')};
    border-color: ${themeGet('primary.0', '#008489')};
  }
  .wrapper-fixed{
    max-width: 1220px;
    padding: 15px 24px!important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .wrapper-horizontal{
    max-width: 1220px;
    padding: 0px 24px!important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .wrapper-public-page{
  max-width: 1220px;
  padding: 15px 24px!important;
  margin-left: auto !important;
  margin-right: auto !important;
}
 /* @media only screen and (min-width: 1441px){
    .landing-wrapper-header{
      max-width: 1220px;
      padding: 15px 73px!important;
    }
    .wrapper-public-page{
      padding: 0px 24px!important;
    }
  }
  }*/
  @media only screen and (max-width: 991px){
    .wrapper-fixed{
      padding: 15px 24px!important;
    }
    .wrapper-horizontal{
      padding: 0px 24px!important;
    }
  }
  .list-description {
    list-style: none;
    li:before {
      content: '✓';
    }
  }
  .rounded-full {
    border-radius: 9999px !important;
  }
  .ant-btn:active{
    color:${themeGet('primary.0', '#008489')};
  }
  .special_package_modal{
    .ant-modal-body {
      padding: 0px !important;
    }
    .ant-modal {
      padding: 0 15px;
      top: 5%;
    }
  }
  
  footer {
    .menu_spin {                    
        .ant-spin-dot-item {
          background-color: #A77F34;
        }
    }
  }

  #hotel-partner-info{
    .menu_spin { 
      display: flex;
      justify-content: center;                   
      .ant-spin-dot-item {
        background-color: #A77F34;
      }
    }
  }
  
  .ant-modal-confirm-body > .anticon {
    color:${themeGet('primary.0', '#008489')} !important;
  }
  
  .cancel-button {
    color: #e53e3e !important;
    border: 1px solid #e53e3e !important;
  }
  
  .public-button {
    color: ${themeGet('color.1', '#ffffff')} !important;
    border-color: ${themeGet('primary.0', '#008489')} !important;
    background-color: ${themeGet('primary.0', '#008489')} !important;
  }

  .public-button-filtering {
    color: ${themeGet('color.1', '#ffffff')} !important;
    border-color: ${themeGet('primary.0', '#008489')} !important;
    background-color: ${themeGet('primary.0', '#008489')} !important;
    float: right;
    margin-bottom: 10px;
  }
  
  .white-button {
    color: ${themeGet('primary.0', '#008489')} !important;
    border-color: ${themeGet('primary.0', '#008489')} !important;
  }
  
  @media only screen and (max-width: 480px){
    .footer-menu{
      width:100%;
      overflow: hidden;
      display:inherit;
      text-align: left;
     
    }
    .footer-menu li{
      display: inline-block;
      flex: 1 1 auto;
    }
  }
  @media only screen and (min-width:1020px) and (max-width: 1167px){
    .footer-menu li {
      padding:0 8px !important;
    }
  }
  @media only screen and (min-width:765px) and (max-width: 1180px){
    .hotels-to-go{
      display:block;
    }
  }
  .footer-text-items{

  }
  @media only screen and (min-width:495px) and (max-width: 635px){
    .footer-text-items{
      flex-direction: column;
    }
  }
  .under-construction-modal .ant-modal-body {
    padding: 0 !important;
  }
  .hidden-construction .ant-modal-confirm-btns{
    border-color: white !important;
  }
  .hidden-btn{
    color: white !important;
    background: white !important;
    border-color: white !important;
    text-shadow: none!important;
    -webkit-box-shadow: none !important;
    box-shadow:none !important;
    border-color:white !important;
    height: 15px;
  }
  .birthDate .SingleDatePicker, .birthDate .SingleDatePickerInput, .birthDate .DateInput{
    width: 100%;
  }
  .birthDate .DateInput_input {
    font-weight: 500;
    font-size: 14px;
  }
        
  @media only screen and (max-width: 768px),
              screen and (max-width: 768px) and (orientation: landscape){
    .ant-modal {
        top: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        
          .ant-modal-content,
          .special-card-container {
            box-shadow: none !important;
          }
    }
    
    .reservation_modal .ant-modal-content .ant-modal-body .reservation_sidebar {
        box-shadow: none !important;
    }
          
    .ant-modal-root .ant-modal-mask {
        background-color: ${themeGet('color.1', '#ffffff')} !important;
    }
  }
  
   .ant-modal-mask,
   .ant-drawer-mask {
        background-color: rgba(255, 255, 255, 0.8) !important;
  }
  
  .cookie-container {
    padding:0px !important;
      .accept-cookies-bnt {
        color: ${themeGet('color.1', '#ffffff')} !important;
        background-color: #039536;
      }
            
      @media only screen and (min-width: 550px) and (max-width: 900px) {
        img {
            margin: 0 auto;
           // width: 80% !important;
        }
      }
  }
  .ant-drawer-body{
    overflow:hidden !important
  }

  .view-photo {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-left:15px;
  }

  .tw-icon {
    width: 130px;
    min-width: 130px;
    height: 40px;
    min-height: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    top: 28.5px;
    right: 25px;
  }
  @media only screen and (max-width: 460px){
    .tw-icon {
      bottom: 0;
      right: 24px;
    
    }
  }
  .position-static{
    position:static!important;
    border :0px!important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  
  .tw-icon-en{
    width: 118px !important;
    min-width: 118px !important;
  }
  .card-tag-wrapper{
    width: 100px;
    min-width: 100px;
    height: 33px;
    min-height: 33px;
    right: 0.005px;
    top: 15px;
  }
  
 .spec-box{
    width: 100px;
    height: 100px;
    text-overflow: ellipsis;
    line-height:1.3em;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 16px;
    @media only screen and (min-width: 1200px) and (max-width: 1380px){
      margin-left: 8px;
    }
  }
  .green-box{
    width: 100px;
    height: 100px;
    overflow: auto;
    text-overflow: ellipsis;
    line-height:1.3em;
  }

  .spec-col{
    padding-left: 0px !important;
  }

  .ant-modal-confirm-title{
    font-weight: bold !important;
  }

  .ant-steps-item-icon{
    background-color: #fff !important;
    border-color: #A77F34 !important;
  }
  .ant-steps-item-process 
  .ant-steps-item-icon 
  .ant-steps-icon,
  .ant-steps-item-finish 
  .ant-steps-item-icon > 
  .ant-steps-icon {
     color: #A77F34 !important;
    }

 
  .ant-steps-item-finish > 
  .ant-steps-item-container > 
  .ant-steps-item-content > 
  .ant-steps-item-title::after {
      background-color: #505050 !important;
  }

  .ant-btn-steps:hover, .ant-btn-steps:focus {
    color: #A77F34 !important;
    background: #fff;
    border-color: #A77F34 !important;
  }

  .career-right-col{
    display: block;
   // overflow: auto;
    height: 100%;
  }
 
  
  // CSS Transition Group

.career-left-panel-enter {
  height: 350px;
}
.career-left-panel-enter-active {
  height: 1000px;
  -webkit-transition: height .5s ease;
}
.career-left-panel-exit-active {
  height: -1000px;
  -webkit-transition: height .5s ease;
}

.dirRTL {
  direction: rtl;
}
.company-logo-box{
  background-color: #F6EFE3;
  border-color: #F6EFE3;
  position: relative;
}
.company-vertical-line{
  border-left: 2px solid;
  border-color: #A77F34;
  top: 0;
  bottom: 0;
  position: absolute;
}
.company-text{
  list-style: outside;
}
li-text{
  text-indent: 1em;
}
.margin-left-total{
  margin-left: 328px;
}
.sticky-footer{
  top: 0;
  bottom:0;
  position:sticky;
  overflow-y:scroll;
  overflow-x:hidden;
}
.green-rating-title{
  color: green;
}
a.green-rating-title:hover{
  color: green;
}
.green-filter-width{
  width: 55px;
}
.ant-space{
  vertical-align: middle;
}
.combination-icon-margin{
  margin-left: 1px;
  margin-top: 12px;
}
.image-VIP{
  height:554px;
  display: block;
  width: auto;
  max-height: 100%
}
.ribbon{
  position: absolute;
}
.ribbon-VIP {
  width: 40px;
  height: 70px;
  background: #A77F34;
  top: -10px;
  left: 30px;
  
  &:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  border-left:5px solid transparent;
  border-right:5px solid #755313;
  border-bottom:5px solid #755313;
  border-top:5px solid transparent;
  }
}
  .ribbon-VIP-Card {
    width: 40px;
    height: 70px;
    background: #A77F34;
    top: -10px;
    
    &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    border-left:5px solid transparent;
    border-right:5px solid #755313;
    border-bottom:5px solid #755313;
    border-top:5px solid transparent;
    }
}
.text-green { color:#039536 ;}

.text-dark { color:#2C2C2C ;}

.bg-green { background-color:#039536 ;}

.add-btn-link{
  float: right;
  color: #2c2c2c;
  font-size: 15px;
  &:hover {
    color :#A77F34;
  }
}
.white-button{
  border: 1px solid #A77F34;
  border-radius: 6px;
}
.show-more-link{
  border:none;
  color:#A77F34;
  text-decoration:underline;
  padding-left:0px!important;
  &:hover {
    color :#A77F34;
  }
}
.show-more-link-icon {
  width: 30px;
  float: left;
  text-align: left;
  margin-left:5px;
}
.align-right-hotel-categories{
  font-weight: 600;
  position: absolute;
  right: 0;
}
.reset-btn-mobile{
  color: #989898 !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;
  padding-right: 0px;
  &:hover {
    color: #475569 !important;
    }
}
.reset-btn{
  color: #989898;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 0px 4px 7px;
  &:hover {
    color: #475569;
    }
}
.pr-0{
  padding-right:0px;
}
.text-map{
  word-wrap: break-word;
  width: 100px;
}
.infoWindow-image{
  object-fit: cover;
  height: 70px;
}
.text-dark-destination-card{
  color: #202020;margin-bottom: 10px;
}
.text-light-destination-card{
  color:#989898;
}
.bg-gray-light { background-color: #FAFAFA;}

.img-box-destination{
  height:300px;
  width: 100%;
}
@media only screen and (min-width: 1024px){
  .text-box-destination{
    height:300px;
  }
}
.map-button{
  right: 24px!important;
  top: -0.5px!important;
  z-index: 5;
  position: absolute;
}
.boxcode-button{
  width: 100px;
  height: 40px;
  border-radius: 6px;
}
.boxcode-input{
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
}
.golf-odd-row{
  background-color: #F6F6F6;
}
.golf-even-row{
  background-color: #f7fafc;;
}
@media only screen and (min-width: 768px){
  .golf-left-panel{
    padding-right: 50px !important;
  }
}
.map-button-style{
  background-color: #424242;
  height: 37px;
  border-radius: 19px;
  opacity: 1;
}
.map-button-style:focus {
  background-color: #424242!important;
  border-color:  #424242!important;
  color: white!important;
}
.faq-font{
  font-weight: bold !important;
}
.faq-text-font-color{
  color: #585858;
}
.green-badge-header{
  margin-right:-100px!important;
  margin-left:-100px!important;
  margin-top:-40px!important;
  @media only screen and (max-width: 970px){
    margin-top:-20px!important;
  }
}
.profile-avatar{
  margin-left: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px){
  .profile-avatar{
    margin-left: 8px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1299px){
.book-text{
  font-size: 12px;
}
}
.ant-table-pagination-right {
  justify-content: flex-start!important;
}
.ant-pagination-item {
  border-radius: 50%!important;
  border: 0px!important;
  min-width: 25px!important;
  height: 25px!important;
  line-height: 22px!important;
}
.ant-pagination-item a {
  color: #A77F34!important;
}
.ant-pagination-item-active {
  background: #A77F34!important;
}
.ant-pagination-item-active a {
  color: #fff!important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-color: #F1EAE2!important;
  border: 0px!important;
  border-radius:50%!important;
  width: 25px!important;
  height: 25px!important;
  line-height: 22px!important;
}
.ant-pagination-prev button, .ant-pagination-next button {
  color: #A77F34!important;
}
.ant-pagination-item-ellipsis{
  color: #A77F34!important;
  background: #fff!important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #EADECA!important;
  border-radius: 5px!important;
}
.ant-pagination-item-link > span
{
  vertical-align: 1px!important;
}
div .grecaptcha-badge {
  z-index: 20 !important;
  }
.cookie-link{
  color: var(--custom_green);
}
.gc-odd-panels{
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
.gc-even-panels{
  background-color: #fdfdfd;
  border-color: #fdfdfd;
}
.special-offer-btn{
  color: #a77f34;
  border-color: #A77F34;
  background-color: #f6f0e5;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #9d9d9d1a;
  border: 1px solid #a77f34;
}
.special-offer-btn:hover
{
  color: #a77f34 !important;
  border-color: #A77F34 !important;
  background-color: #f6f0e5 !important;
  opacity: 0.85;
}
.img-box-hotel-with-age{
    height:240px;
    width: 100%;
  }
  .img-box-hotel{
    height:180px;
    width: 100%;
  }
  @media only screen and (min-width: 1024px){
    .text-box-hotel{
      max-height:240px;
    }
  }
  .ant-comment-content{
    background-color: #F5F5F5;
    border-radius: 5px;
    padding: 2px;
    max-width: 300px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1070px) {
    .ant-comment-content{
      max-width: 230px;
    }
  }
  @media only screen and (min-width: 300px) and (max-width: 1023px) {
    .ant-comment-content{
      max-width: 1000px;
    }
  }
  .booking-detail-box{
    background-color: #FAFAFA;
    border-radius: 5px 0px 0px 5px;
  }
  .dirRTL > .ant-comment-inner > .ant-comment-avatar {
    margin-right:0px!important;
    margin-left:12px!important;
  }
  .dirRTL > .ant-comment-inner > .ant-comment-content > .ant-comment-content-author >.ant-comment-content-author-time {
    direction: ltr!important;
    margin-right:0px!important;
  }
  .dirRTL > .ant-comment-inner > .ant-comment-content > .ant-comment-content-author >.ant-comment-content-author-name {
    padding-right:0px!important;
    margin-right:0px!important;
  }
  .ant-comment-content{
    padding :10px!important;
  }
  .ant-comment-inner {
    width: max-content;
  }
  @media only screen and (min-width: 1023px) and (max-width: 1100px){
  .ant-comment-content-author{
    display: block !important;
  }
  .ant-comment-content-author >span{
    padding-right: 0px !important;
  }
}
.fixed-buttons{
  padding-bottom: 25px !important;
  margin-right: 24px !important;
}
@media only screen and (max-width: 991px){
  .fixed-buttons{
    padding-bottom: 40px;
  }
}
.filterbar-line {
  border-bottom: 1px solid #e6e6e6 !important;
  background-color: #ffffff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #F1EAE2!important;
}
.banner-content-mt {
  margin-top:200px;
}
.add-btn-link:focus {
  color: #2c2c2c;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
{
white-space: nowrap;
}
.z-7{
  z-index:7!important;
}
.hotel-parter-heading{
  font-weight: 700!important;
  font-size: 20px!important;
}
@media only screen and (max-width: 340px){
    .ant-comment-content-author{
        display: block !important;
    }
    .attached-img{
      width: 70px;
    }
}
.text-block {
  position: absolute;
  background-color: white;
  color: black;
  padding: 25px;
  border-radius: 5px;
  opacity: 0.9;
  width: 400px;
  top: 50%;
	left: 30%;
	transform: translate(-50%, -50%);
}
@media (min-width: 768px) and (max-width: 1023px){
  .text-block {
   width: 300px !important;
  }
}
@media (min-width:640px) and (max-width: 767px){
  .text-block {
   width: 240px !important;
   padding: 12px !important;
  }
}
@media (max-width: 639px){
  .text-block {
    width: 150px !important;
    padding: 10px !important;
   }
  .text-slider{
    font-size: 8px !important;
  }
  .solution-btn{
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
.text-slider{
  text-align: left;
  letter-spacing: 0.4px;
  color: black;
  line-height: 1.6;
  font-weight: 600;
}
.solution-btn{
  border: 1px solid #039536;
  border-radius: 22px;
  color: var(--custom_green);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 15px;
  float: right;
  font-weight: 600;
}
.solution-btn:hover{
  background-color: #039536;
  color: white;
}
.alice-carousel__prev-btn{
  text-align: left;
  position: absolute;
  top:50%;
  transform: translatey(-50%);
  cursor: pointer;
}
.alice-carousel__next-btn {
  text-align: right;
  position: absolute;
  right:0px;
  top:50%;
  transform: translatey(-50%);
  cursor: pointer;
}
@media (max-width: 767px){
  .alice-carousel__prev-btn {
    left:-24px !important;
  }
  .alice-carousel__next-btn {
    right:-24px !important;
  }
  .alice-carousel__next-btn > svg {
    transform: scale(0.5);
  }
  .alice-carousel__prev-btn > svg {
    transform: scale(0.5);
  }
}
.sticky-outer-wrapper.isSticky > .sticky-inner-wrapper  {
  padding-top: 3px;
}
svg{
  display: inline !important;
}
@media only screen and (max-width: 500px){
  .img-height{
    min-height: 350px;
    width: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 1441px){
    .sticky-outer-wrapper.isSticky > .sticky-inner-wrapper  {
        padding-top: 11px;
      }
  }
.overview div.text-base > h2 { font-weight: bold; }
.ant-input[disabled] {
  color: gray !important;
}
.pdf-btn{
  border-style: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0px 0px  !important;
  margin-right: 6px;
}
.boxCode-btn{
  background: #F3E9D7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #67543026;
  height: 215px;
  color: #A77F34 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  padding-top: 8px !important;
}

.boxCode-btn: hover{
  background-color: #A77F34 !important;
  color: white !important;
}
.voucher-card {
  position:relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #0000000D;
  height: 215px;
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding-top: 55px;
  letter-spacing: 0.4px;
}
.bottom-right {
  position:absolute;
  right: 0px; 
  bottom: 0px;
  color: #F5EFE6;
}
.tick-box{
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 46px;
  height: 46px;
}
.available-voucher{
  position:relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #0000000D;
  height: 210px;
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  text-align: lft;
  padding-top: 55px;
  letter-spacing: 0.4px;
}
@media only screen and (max-width: 404px) {
  .available-voucher{
    font-size: 14px;
  }
}
.number-align{
  margin-left: 113px;
}
.used-voucher-text{
 color: #989898;
}
.voucher-number{
  font-size: 48px;
}
@media only screen and (max-width: 404px) {
  .voucher-number{
    font-size: 22px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .voucher-number{
    font-size: 22px;
  }
}

}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}
.back-img {
  display: flex;
  align-items: center;
  height: 503px;
  background-image: url('http://app.theghbdev.com/media/cloud_files/media1656239899.284963.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.back-img>div {
  width: 49%;
  margin: auto 40px;
}

@media (min-width:750px) and (max-width:1024px) {
  .back-img {
  background-image: url('http://app.theghbdev.com/media/cloud_files/media1656318247.486207.png'); 
  }
}
@media (min-width:750px) and (max-width:899px) {
  .back-img {
  height: 453px; 
  }
}
@media (min-width:520px) and (max-width:749px) {
  .back-img {
  background-image: url('http://app.theghbdev.com/media/cloud_files/media1656318247.482345.png'); 
  align-items: end;
  height: 840px;
  }
  .back-img>div {
    width: 100%;
    margin: 40px 40px;
  }
}
@media (max-width:519px) {
  .back-img {
    display: flex;
    align-items: end;
    height: 760px;
    background-image: url('http://app.theghbdev.com/media/cloud_files/media1656318247.475577.png');
  }

  .back-img>div {
    width: 100%;
    margin: 40px 40px;
  }
}
.google-map-expand-custom-button{
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 22px;
  text-align: center;
  width: 40px;
  height: 40px;
  color: #666666;
  &:hover{
    background-color: #E2E8F0;
    color: #424242;
  }
}
.google-map-expand-custom-button > div{
  padding-left: 5px;
  padding-right: 5px;
  font-size: 28px;
  padding-top:3px;
}
.boxcodeInfo .field-label{
  display: inline !important;
}
.boxcodeInfo .ant-input{
  margin-top: 10px;
}
.boxcodeInfoCodeRequest .ant-input{
  margin-top: 1px !important;
}
.boxCode-icon-box{
  z-index: 50;
  position: absolute;
  max-width: 400px;
  padding: 18px;
  background-color: white;
  border-radius: 3px;
  left: 0;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)
}
.boxCode-icon-booking-section-box{
  z-index: 50;
  position: absolute;
  width: 367px;
  padding: 18px;
  background-color: white;
  border-radius: 3px;
  left: 0;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)
}
@media (max-width: 420px){
  .boxCode-icon-booking-section-box{
    width: 307px;
  }
}
.info-box-icon{
  border: none !important;
  padding: 0px !important;
}

.faq-category-title{
  font-weight: 700;
  font-size: 16px;
  color: #A77F34;
  padding-top: 14px
}

.footer-address{
  padding-top: 19.5px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.11px;
}

.footer-social-media{
  color: #F8F8F8 !important;
  background-color: #424242;
  width: 20px !important;
  height: 20px;
  padding: 5px;
  border-radius: 20px;
}

.footer-copyright{
  font-size: 12px;
  line-height: 50px;
  color: #989898;
  letter-spacing: 0.12px;
}

  .form-alignment{
    margin-left: 50px !important;
  }
  .field-label{
    float: left !important;
  }
  .field-margin{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .feedback {
    text-align: left;
  }
}
.color-green {
  color: var(--custom_green);
}
.newsletter-button {
    float: right;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #039536 !important;
    border-color: #039536 !important;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    font-weight: semibold;
    color: white !important;
    white-space: nowrap;
    padding-left: 15px !important;
    padding-right: 15px !important;
    //width: 100%;
    border-radius: 0.375rem;
    cursor: pointer;
    float: right;
    text-transform: none !important;
}
.newsletter-button:disabled {
  background: #e0e0e0 !important;
  border-color: #d9d9d9 !important;
  color: #989898 !important;
}
.submit-booking-request-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.header-box {
  left: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f4f6;
}
.header-overlay-text{
  max-width: 767px;
text-align: center;
}
.mandatory-field-constant-text{
  font-size: 15px;
  font-weight: 500;
  color: #4a5568;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity .2s ease-in-out;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
}

.profilepic__content-icon-edit {
  position: absolute;
  top: 10%;
  right: 0;
  color: black;
  opacity: 1;
}
.profilepic__content-icon-delete {
  position: absolute;
  right: 0;
  bottom: 20%;
  color: black;
  opacity: 1;
 
}
.circle-icon{
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: white;
  border-color: white;
  color: #3F4254;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 0;
}
.profilepic__text {
  font-size: 12px;
  font-weight: 700;
  width: 50%;
  text-align: center;
}
body.waiting * { cursor: wait !important; }

.info-center {
  background: #039536;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 35px 0px 0px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
}
.info-center-margin {
  position: fixed;
  right: 0;
  z-index: 99;
  top: 400px;
}

.info-center-icon {
  font-size: 28px;
}
.info-center-text {
  display: none;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 50px; 
}

.info-center-text > a: focus{
  color: white !important;
}
.info-center-text > a: hover{
  color: white !important;
}
.ant-modal-close-x:focus-visible{
  outline: none !important;
  border: none !important;
}
.ant-modal-close:focus{
  outline: none !important;
  border: none !important;
}
.ant-checkbox + span {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.display-inline-block{
  display: inline-block !important;
}
.text-font-weight{
  font-weight: 500 !important;
}
@media (min-width: 992px) {
  .newsletter-profile-section{
    justify-content: space-between !important;
    flex-direction: row !important;
  }
}
.profile-sections-title{
  font-size: 14px;
  text-align: left;
  color: #989898;
  margin-top: 20px;
}

.booking-form-animate-left{
  position:relative;
  animation:animateleft 0.5s
}

@keyframes animateleft{
  from{left:-300px;}
  to{left:0;}
}

.booking-form-animate-right{
  position:relative;
  animation:animateright 0.5s
}

@keyframes animateright{
  from{right:-300px;}
  to{right:0;}
}

.booking-form-back-button{
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: #A77F34 solid 1px;
  color: #A77F34;
  background-color: #FFFFFF;
}

.booking-form-back-button:hover{
  border: #A77F34 solid 1px;
  color: #A77F34;
  background-color: #FFFFFF;
}

.booking-form-company-information{
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 0px;
  float: left;
}

.booking-form-company-information-text{
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 8px;
}

.booking-form-no-show-checkbox{
  padding-top: 2px;
  padding-bottom: 44px;
}

.offer-modal-page-number{
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  padding-bottom: 15px;
  color: #2c2c2c;
}

.offer-modal-description{
  font-size: 14px;
  line-height: 25px;
}

.no-show-cancellation-link {
  padding-top: 2px;
}

.booking-form-payments {
  margin: 7px 0 0 2px;
}

.booking-form-payments > img {
  display: inline-block;
  height: 22px;
  border-radius: 2px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
  margin: 2px;
}



.offer-form-payments {
  margin-top: 3px;
}

.offer-form-payments > img {
  display: inline-block;
  height: 31px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
  margin: 2.5px;
}


::placeholder {
  color: #C6C6C6 !important;
 }

 :-ms-input-placeholder {
   color: #C6C6C6 !important;
  }
 
::-ms-input-placeholder {
  color: #C6C6C6 !important;
 }

.credit-card-input::placeholder {
  color: #CFCFCF !important;
 }

 .credit-card-input:-ms-input-placeholder {
   color: #CFCFCF !important;
  }
 
.credit-card-input::-ms-input-placeholder {
  color: #CFCFCF !important;
 }

 .price-card-separator-dot{
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 22px 9px 0 0;
  border-radius: 3px;
  background: ${themeGet('primary.0', '#008489')};
 }
.column-right-width{
   width:38%;
}
.column-left-width{
  width: 58%;
}

@media (min-width: 992px) {
  .flex-row-frm{
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .column-right-width{
   width:100% !important;
 }
 .column-left-width{
  width: 100% !important;
}
   .margin-top-frm{
     margin-top: 20px;
   }
 }
 .newsletter-popup{
 .ant-modal-body{
  padding: 0px !important;
 }
}
.newsletter-content-text{
  margin-left: 6px;
}
@media only screen and (max-width: 767px){
  .newsletter-frm{
    padding-bottom: 0px !important;
}
}
.hotel-partner-box{
  background-color: #F8F8F8;
  width: 100%;
  display: flex;
  padding: 40px 150px;
}
@media only screen and (max-width: 640px){
  .hotel-partner-box{
    padding: 40px 70px !important;
  }
}
.learn-more-alignment{
  margin-right:10%;
}
.month-banner-slide-box{
  position: absolute;
  left:8%;
  top: 27%;
}
.month-banner-btn-margin-top{
  margin-top:32px;
}
.month-banner-first-text{
  color: #2F5E26;
  font-size: 25px;
  font-weight: 700;
  margin-top:15px;
  width:37%;
}
.month-banner-second-text{
  color: #2D2D2D;
  font-size: 20px;
  font-weight: 600;
  margin-top:15px;
}
.month-banner-third-text{
  color: #2D2D2D;
  font-size: 25px;
  font-weight: 700;
}
.month-banner-btn{
  border: 1px solid #2F5E26;
  border-radius: 22px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
  background-color: #2F5E26;
  color: white;
}
.month-banner-margin-top{
  margin-top: 16px;
 }
 @media (min-width:992px) and (max-width:1122px){
  .month-banner-slide-box {
    top:23%;
  }
  .month-banner-first-text{
    font-size: 24px;
  }
  .month-banner-second-text{
    font-size: 20px;
    margin-top: 12px;
  }
  .month-banner-third-text{
    font-size: 20px;
    margin-top: 10px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:14px !important;
  padding: 8px 14px !important;
  }
  .month-banner-margin-top{
    margin-top: 12px;
   }
   .month-banner-btn-margin-top{
    margin-top: 10px;
   }
}
@media (min-width:768px) and (max-width:991px){
  .month-banner-slide-box {
    top:23%;
  }
  .month-banner-first-text{
    font-size: 18px;
  }
  .month-banner-second-text{
    font-size: 14px;
    margin-top: 12px;
  }
  .month-banner-third-text{
    font-size: 13px;
    margin-top: 10px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:14px !important;
  padding: 8px 14px !important;
  }
  .month-banner-margin-top{
    margin-top: 12px;
   }
   .month-banner-btn-margin-top{
    margin-top: 10px;
   }
}
@media (min-width:640px) and (max-width:767px){
  .month-banner-slide-box {
    top:23%;
  }
  .month-banner-first-text{
    font-size: 14px;
  }
  .month-banner-second-text{
    font-size: 12px;
    margin-top: 9px;
  }
  .month-banner-third-text{
    font-size: 11px;
    margin-top: 8px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:12px !important;
  padding: 8px 8px !important;
  }
  .month-banner-margin-top{
    margin-top: 10px;
   }
   .month-banner-btn-margin-top{
    margin-top: 8px;
   }
}
@media (min-width:521px) and (max-width:639px){
  .month-banner-slide-box {
    top:22%;
  }
  .month-banner-first-text{
    font-size: 12px;
  }
  .month-banner-second-text{
    font-size: 9px;
    margin-top: 6px;
  }
  .month-banner-third-text{
    font-size: 9px;
    margin-top: 5px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:10px !important;
  padding: 4px 5px !important;
  }
  .month-banner-margin-top{
    margin-top: 6px;
   }
   .month-banner-btn-margin-top{
    margin-top: 3px;
   }
}
@media (min-width:451px) and (max-width:520px){
  .month-banner-slide-box {
    top:20%;
  }
  .month-banner-first-text{
    font-size: 10px;
  }
  .month-banner-second-text{
    font-size: 9px;
    margin-top: 4px;
  }
  .month-banner-third-text{
    font-size: 9px;
    margin-top: 3px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:8px !important;
  padding: 4px 5px !important;
  }
  .month-banner-margin-top{
    margin-top: 4px;
   }
   .month-banner-btn-margin-top{
    margin-top: 2px;
   }
}
@media (max-width:450px){
  .month-banner-slide-box {
    top:17%;
  }
  .month-banner-first-text{
    font-size: 8px;
  }
  .month-banner-second-text{
    font-size: 7px;
    margin-top: 3px;
  }
  .month-banner-third-text{
    font-size: 7px;
    margin-top: 2px;
    width: 80%;
  }
  .month-banner-btn{
  font-size:6px !important;
  padding: 4px 5px !important;
  }
  .month-banner-margin-top{
    margin-top: 4px;
   }
   .month-banner-btn-margin-top{
    margin-top: 2px;
   }
}

.month-banner-btn:hover{
  color: #429343;
  opacity: 0.95;
}

.learn-more-btn{
  border: 1px solid #ffffff;
  border-radius: 22px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.learn-more-btn:hover{
  background-color: white;
  color: #B80000;
}
.hope-text{
  font-size: 72px;
  color: white;
  font-weight: 800;
}
.holiday-plan-text{
  font-weight: bold;
  font-size: 28px;
  color: white;
}
.heroImgContainer{
  width:27rem;
}
.newsLetterImgContainer{
  min-width: 89px;
  min-height: 80px;
}
.heroImgContainer img, .newsLetterImgContainer img{
  color: #fff0;
}
.alignment-position{
  padding-left: 30px;
  padding-right: 30px;
  top:25px;
  height: 90%;
   transform: translate(0, 0%);
}
.height-90{
  height: 90%;
}
@media screen and (max-width: 400px) {
  .height-90{
    height: 87% !important;
  }
}
.header-text{
  font-size: 20px;
  color: #2C2C2C;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-top: 16px;
}

.header-top-text{
  font-size: 14px;
  color: #2C2C2C;
  letter-spacing: 0.14px;
}

@media screen and (max-width: 425px){
  .header-text{
    font-size: 14px !important;
  }
  .header-top-text{
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1150px){
  .holiday-box {
    width: 350px !important;
  }
  .hope-text{
    font-size: 60px !important;
  }
  .holiday-text {
    font-size: 45px !important;
    line-height: 1 !important;
  }
  .padding-text{
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .holiday-plan-text {
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
  .holiday-box {
    width: 260px !important;
  }
  .hope-text{
    font-size: 40px !important;
  }
  .holiday-text {
    font-size: 35px !important;
    line-height: 1 !important;
  }
  .padding-text{
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .holiday-plan-text {
    font-size: 18px !important;
  }
  .learn-more-btn {
    padding: 5px 10px !important;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px){
  .holiday-box {
    width: 200px !important;
  }
  .hope-text{
    font-size: 30px !important;
  }
  .holiday-text {
    font-size: 25px !important;
    line-height: 1 !important;
  }
  .padding-text{
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .holiday-plan-text {
    font-size: 14px !important;
  }
  .learn-more-btn {
    padding: 5px 10px !important;
  }
}
@media only screen and (max-width: 639px){
  .holiday-box {
    width: 130px !important;
  }
  .hope-text{
    font-size: 20px !important;
  }
  .holiday-text {
    font-size: 15px !important;
    line-height: 1 !important;
  }
  .padding-text{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .holiday-plan-text {
    font-size: 10px !important;
  }
  .learn-more-btn {
    padding: 2px 3px !important;
    font-size: 8px !important;
  }
  .small-text{
    font-size: 8px;
  }
}
@media only screen and (min-width: 768px){
  .menu-width{
    width: 45% !important;
  }
}
@media only screen and (max-width: 768px){
  .td-text{
    white-space: unset !important;
  }
  .ant-table-thead > tr > th
  {
    white-space: unset !important;
  }
}
@media only screen and (max-width: 415px){
  .booking-modal-btn{
    margin-top: 5px;
  }
  .booking-logo-modal{
    padding-top: 8px;
  }
}
.ant-btn-primary.booking-btn{
  background: #8B8680 !important;
  border-color: #8B8680 !important;
  color: white !important;
}
.checkbox-margin{
  margin-top: -21px;
}
.checkbox-package-margin{
  margin-top: -42px;
}
input[type='checkbox']:checked {
  accent-color:#A77F34 !important;
}
input[type='radio']:checked {
  accent-color:#A77F34 !important;
}
div.job-desc > p.truncate{
  white-space: unset !important;
}
@media (max-width:767px){
  .landing-header-img{
    top: 45% !important;
    transform: translateY(-55%) !important;
  }
}
.landing-carousel .alice-carousel > div > div {
  border-radius: 6px;
}
`;
export default GlobalStyle;
