import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const FooterWrapper = styled.footer`
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active {
    background-color: transparent;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item a:hover {
    color: #000000;
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    color: #000000;
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
  }
  .footer-wrapper {
    max-width: 1220px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const MenuWrapper = styled.div`
  @media only screen and (max-width: 480px) {
    margin-top: 30px;
    padding-left: 0;
  }

  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }

    li {
      margin: 0 30px;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;

      @media only screen and (max-width: 480px) {
        margin: 0 7px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-menu-item-selected {
        background-color: transparent;
      }

      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      transition: color 0.2s ease-in-out;

      a {
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#008489')};
        }
      }
    }
  }
`;

export const CopyrightArea = styled.div`
  color: ${themeGet('text.1', '#909090')};
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 30px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

export const SecondaryFooter = styled.div`
  @media only screen and (max-width: 1200px) {
    height: 74px;
  }
`;

export default FooterWrapper;
