import moment from 'moment';
import React, { ReactElement } from 'react';
import FooterWrapper from './Footer.style';

type Props = {
  logo?: ReactElement;
  menu?: ReactElement;
  icon?: ReactElement;
};

const Footer = ({ logo, menu, icon }: Props): ReactElement => {
  return (
    <FooterWrapper className="z-5">
      <div className="bg-footerGray">
        <div className="flex flex-col items-center justify-between w-full py-8 m-auto footer-wrapper md:flex-row">
          {logo}
          {icon}
          {menu}
        </div>
        <div className="mt-9 footer-copyright m-auto footer-wrapper">
          &copy; copyright {moment().year()} Green Habitat GmbH
        </div>
      </div>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  logo: undefined,
  menu: undefined,
  icon: undefined,
};

export default Footer;
