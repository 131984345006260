import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { base, themed } from '../Base';

const TextWrapper = styled('p')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed('Text'),
);

type Props = {
  content?: string | ReactElement;
  as?: any;
  fontFamily?: string | number | Array<string | number>;
  fontWeight?: string | number | Array<string | number>;
  textAlign?: string | number | Array<string | number>;
  lineHeight?: string | number | Array<string | number>;
  letterSpacing?: string | number | Array<string | number>;
  color?: string;
  className?: string;
};

const Text = ({ as, content, ...props }: Props): ReactElement => (
  // eslint-disable-next-line
  <TextWrapper as={as || 'p'} {...props}>
    {content}
  </TextWrapper>
);

Text.defaultProps = {
  content: undefined,
  as: undefined,
  fontFamily: undefined,
  fontWeight: undefined,
  textAlign: undefined,
  lineHeight: undefined,
  letterSpacing: undefined,
  color: undefined,
  className: undefined,
};

export default Text;
