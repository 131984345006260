import React, { ReactElement, useState } from 'react';
import DefaultFallbackImage from 'assets/destination-placeholder-cover.jpeg';
import css from 'csstype';

type Props = {
  src: string | false;
  alt?: string;
  title?: string;
  fallbackImage?: string;
  className?: string;
  style?: css.Properties;
  callBackError?: any;
};

const Image = ({
  src: imageSrc,
  alt,
  fallbackImage,
  className: classNameProp,
  style,
  callBackError,
  title,
}: Props): ReactElement => {
  const [src, setSrc] = useState<string>(imageSrc || '______failed.jpg');
  const [className, setClassName] = useState(classNameProp ?? '');
  const onError = (): void => {
    setSrc(fallbackImage ?? DefaultFallbackImage);
    setClassName(`${className} image-place-holder`);
    callBackError && callBackError(true);
  };
  return (
    <img
      src={src}
      alt={alt ?? src}
      onError={onError}
      className={className}
      style={style}
      title={title}
    />
  );
};

Image.defaultProps = {
  alt: undefined,
  fallbackImage: undefined,
  className: undefined,
  style: undefined,
  title: undefined,
  callBackError: (): void => {
    // Empty function
  },
};

export default Image;
