import React, { ReactElement, useRef } from 'react';
import { TFunction } from 'i18next';
import useTranslation from 'localizer';
import Portal from 'components/UI/Portal/Portal';
import austriaFlag from 'assets/images/flags/austria.svg';
import { useHistory } from 'react-router-dom';
import unitedKingdomFlag from 'assets/images/flags/united_kingdom.svg';
import locales, { BRITISH, AUSTRIAN } from 'settings/locales';
import { useOnClickOutside } from './useOnClickOutside';
import { Wrapper, Container } from './LocalePortal.style';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isMobile?: boolean;
  onClose?: () => void;
};

export const getFlag = (locale: string): string => {
  switch (locale) {
    case AUSTRIAN:
      return austriaFlag;
    case BRITISH:
      return unitedKingdomFlag;
    default:
      return austriaFlag;
  }
};

export const getCountry = (locale: string, t: TFunction): string => {
  switch (locale) {
    case AUSTRIAN:
      return t('German');
    case BRITISH:
      return t('English');
    default:
      return t('German');
  }
};

const LocalePortal = ({
  isOpen,
  setIsOpen,
  isMobile,
  onClose,
}: Props): ReactElement => {
  const history = useHistory();
  const ref = useRef();
  const { t } = useTranslation();
  useOnClickOutside(ref, () => setIsOpen(false));
  // Add all classes to an array
  const addAllClasses = ['view_with__popup'];

  const changeUserLocale = (locale: string) => (): void => {
    let newUrl = locale === AUSTRIAN ? '' : `/${locale}`;
    let path = `${window.location.pathname}`;
    locales.forEach((each) => {
      path = path.replace(`/${each}`, '');
    });
    newUrl += path;
    history.push(newUrl);
    if (onClose && isMobile) {
      onClose();
    }
  };

  return (
    <Wrapper
      className={`${addAllClasses.join(' ')} ${isOpen ? 'active' : ''}`}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
    >
      <Container
        className={`popup_container ${isMobile ? 'mobile' : ''}`}
        isOpen={isOpen}
        onClick={(): void => setIsOpen(false)}
      >
        {isOpen && (
          <div id="popup">
            <Portal rendererId="popup">
              <ul>
                {locales.map((each) => (
                  // eslint-disable-next-line
                  <li className="lang-item" onClick={changeUserLocale(each)} key={each}>
                    <div style={{ backgroundImage: `url(${getFlag(each)})` }} />
                    {getCountry(each, t)}
                  </li>
                ))}
              </ul>
            </Portal>
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

LocalePortal.defaultProps = {
  isMobile: false,
  onClose: undefined,
};

export default LocalePortal;
