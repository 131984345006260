import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import MapAutoComplete from 'components/Map/MapAutoComplete';
import { SEARCH_ENTITY_PAGE } from 'settings/constant';
import { useLink } from 'utils';
import { NavbarSearchWrapper } from './Header.style';

const NavbarSearch = (): ReactElement => {
  const history = useHistory();
  const { l } = useLink();
  const updateValueFunc = (value: any): void => {
    const { searchedLocation } = value;
    if (searchedLocation) {
      history.push({
        pathname: `${l(SEARCH_ENTITY_PAGE)}/${searchedLocation}`,
      });
    }
  };

  return (
    <NavbarSearchWrapper className="navbar_search">
      <MapAutoComplete
        updateValue={(value: any): void => updateValueFunc(value)}
      />
    </NavbarSearchWrapper>
  );
};

export default NavbarSearch;
