import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: ${(props: { radius?: number; isOpen?: boolean }) =>
    props.radius ? props.radius : 3}px;
  ${(props: { radius?: number; isOpen?: boolean }) =>
    props.isOpen &&
    css`
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
      position: absolute;
      width: 100%;
      z-index: 999;
      min-width: 180px;
      background-color: #ffffff;
      right: 14px;
      top: 24px;
      border-radius: 0.25rem;

      &.mobile {
        left: 10px;
        right: 10px;
        top: 0;
        width: unset;
      }

      ul {
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 1rem !important;
          width: 100%;
          margin-right: 0 !important;
          margin-left: 0 !important;
          text-align: center;

          div {
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 16px;
          }

          &:hover {
            background-color: lightgray;
          }
        }
      }
    `}
`;

export const Wrapper = styled.div`
  position: relative;
`;
