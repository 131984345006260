import React, {
  ComponentType,
  // memo,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import type { Media, HotelCategory } from 'types';
import TextLink from 'components/UI/TextLink';
import css from 'csstype';
import { Link } from 'react-router-dom';
import './card.css';
import { useLink } from 'utils';
import { Row, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '../../localizer';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    paritialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 1,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

type Props = {
  title?: string;
  subtitle?: string;
  body?: string | ComponentType | ReactElement;
  link?: string;
  medias?: Array<Media>;
  favorite?: ReactElement;
  className?: string;
  style?: css.Properties;
  rating?: ReactElement;
  categories?: Array<HotelCategory>;
  hasSpecialOffer?: boolean;
  truncate?: boolean;
  cardTag?: string;
  isGolf?: boolean;
  holeCount?: number;
};

const Card = ({
  className: cardClassName,
  title,
  medias,
  subtitle,
  body,
  link,
  favorite,
  rating,
  categories,
  style,
  hasSpecialOffer,
  truncate,
  cardTag,
  isGolf,
  holeCount,
}: Props): ReactElement => {
  const { l } = useLink();
  const className = cardClassName ?? '';

  const { t } = useTranslation();

  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    if (navigator.vendor.toLocaleLowerCase().includes('apple')) {
      setIsSafari(true);
      setTimeout(() => {
        setIsSafari(false);
      }, 3000);
    }
  }, []);
  return (
    <div className={`${className} card-container`} style={style}>
      <div className="relative duration-200 card">
        {medias && (
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay={isSafari}
            autoPlaySpeed={2000}
            containerClass="container rounded"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            renderDotsOutside={false}
            responsive={responsive}
            showDots
            sliderClass=""
            slidesToSlide={isSafari ? medias.length : 0}
          >
            {medias.map((media) => (
              <>
                <Link to={l(`${link}`)} key={media.media}>
                  {hasSpecialOffer && (
                    <span className="absolute p-1 font-semibold text-black ribbon rounded bg-bootstrapYellow border-bootstrapYellow hover:bg-bootstrapYellow hover:text-black hover:border-bootstrapYellow focus:border-bootstrapYellow focus:text-black focus:bg-bootstrapYellow">
                      {t('Special Offer Available')}
                    </span>
                  )}
                  <img
                    src={
                      media.photoThumbnail ? media.photoThumbnail : media.media
                    }
                    draggable="false"
                    className="absolute inset-x-0 inset-y-0 object-cover w-full h-full"
                    alt={title}
                  />
                </Link>
                {cardTag && (
                  <div className="absolute flex items-center justify-center p-2 bg-white rounded rounded-r-none card-tag-wrapper">
                    <img className="" src={cardTag} alt="tag" />
                  </div>
                )}
              </>
            ))}
          </Carousel>
        )}
        <div className="py-2">
          {subtitle && (
            <div
              className={`mt-1 text-xs text-gray-500 ${truncate && 'truncate'}`}
            >
              {subtitle}
            </div>
          )}
          {title && (
            <>
              {link && (
                <TextLink
                  link={link}
                  content={title}
                  className={`mt-1 text-lg font-bold text-black capitalize duration-200 cursor-pointer hover:text-gh ${
                    truncate && 'block truncate'
                  }`}
                />
              )}
              {!link && (
                <div className="mt-1 text-lg font-bold text-black">{title}</div>
              )}
            </>
          )}

          {isGolf && holeCount !== 0 && (
            <div className="mt-1">
              <div className="inline-block">
                <FontAwesomeIcon icon={['fas', 'pennant']} className="inline" />
              </div>
              <div
                className={`text-xs text-gray-900 inline-block ml-2 -mb-1 ${
                  truncate && 'truncate'
                }`}
              >
                {`${holeCount}-${t('hole')}`}
              </div>
            </div>
          )}
          {body && (
            <div className="overflow-y-hidden text-base leading-normal text-gray-900 ">
              {body}
            </div>
          )}
          <Row>
            <Col xs={12}>
              {rating && (
                <div className="flex items-center mt-px">{rating}</div>
              )}
            </Col>
            {link && categories && categories?.length > 0 && (
              <Tooltip
                placement="topRight"
                style={{
                  color: 'black',
                  boxSizing: 'border-box',
                  backgroundColor: 'white',
                }}
                title={categories.map((category) => category.name).join(', ')}
              >
                <Col xs={12} className="align-right-hotel-categories">
                  <TextLink
                    link={link}
                    className="block truncate mt-1 duration-200 cursor-pointer text-gh hover:text-gh-hover"
                    content={categories
                      .map((category) => category.name)
                      .join(', ')}
                  />
                </Col>
              </Tooltip>
            )}
          </Row>
          {favorite && <div className="absolute favorite">{favorite}</div>}
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  title: undefined,
  subtitle: undefined,
  body: undefined,
  link: undefined,
  medias: undefined,
  favorite: undefined,
  className: undefined,
  rating: undefined,
  categories: undefined,
  style: undefined,
  hasSpecialOffer: undefined,
  truncate: true,
  cardTag: undefined,
  isGolf: false,
  holeCount: undefined,
};

export default Card;
