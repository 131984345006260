import React, { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const CardWrapper = ({ children, className }: Props): ReactElement => {
  return (
    <div className={`card-wrapper ${className ?? ''} flex flex-wrap`}>
      {children}
    </div>
  );
};

CardWrapper.defaultProps = {
  className: undefined,
};

export default CardWrapper;
