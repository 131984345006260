import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SuccessIcon = (): ReactElement => {
  return (
    <FontAwesomeIcon
      icon={['far', 'check-circle']}
      className="text-greenNotification anticon"
    />
  );
};

export default SuccessIcon;
