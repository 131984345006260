import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import {
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from 'styled-system';
import { base, themed } from '../Base';

type Props = {
  children: ReactElement;
  flexBox?: boolean;
  className?: string;
  as?:
    | 'div'
    | 'article'
    | 'section'
    | 'address'
    | 'header'
    | 'footer'
    | 'nav'
    | 'main';
  width?: string | number | Array<string | number>;
  height?: string | number | Array<string | number>;
  fontSize?: string | number | Array<string | number>;
  color?: string | number | Array<string | number>;
  flex?: string | number | Array<string | number>;
  order?: string | number | Array<string | number>;
  alignSelf?: string | number | Array<string | number>;
  display?: string | number | Array<string | number>;
  border?: string | number | Array<string | number>;
  borderTop?: string | number | Array<string | number>;
  borderRight?: string | number | Array<string | number>;
  borderBottom?: string | number | Array<string | number>;
  borderLeft?: string | number | Array<string | number>;
  borderColor?: string | number | Array<string | number>;
  pr?: string | number | Array<string | number>;
  pl?: string | number | Array<string | number>;
  mr?: string | number | Array<string | number>;
  ml?: string | number | Array<string | number>;
};

const BoxWrapper = styled('div')(
  base,
  themed('Box'),
  (props: Props) =>
    props.flexBox &&
    css(
      { display: 'flex' },
      flexWrap,
      flexDirection,
      alignItems,
      justifyContent,
      themed('FlexBox'),
    ),
);

const Box = ({ children, ...props }: any): ReactElement => (
  // eslint-disable-next-line
  <BoxWrapper {...props}>{children}</BoxWrapper>
);

Box.defaultProps = {
  as: 'div',
};

export default Box;
