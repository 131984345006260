import React, { ReactElement } from 'react';
import SectionTitleWrapper, { TitleWrapper, LinkWrapper } from './Title.style';

type Props = {
  className?: string;
  title: ReactElement;
  link?: ReactElement;
  titleWrapperClassName?: string;
};

const SectionTitle = ({
  className,
  title,
  link,
  titleWrapperClassName,
  ...props
}: Props): ReactElement => {
  // Add all classes to an array
  const addAllClasses = ['section_title'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    // eslint-disable-next-line
    <SectionTitleWrapper className={addAllClasses.join(' ')} {...props}>
      {title && (
        <TitleWrapper className={`title_wrapper ${titleWrapperClassName}`}>
          {title}
        </TitleWrapper>
      )}
      {link && <LinkWrapper className="link_wrapper">{link}</LinkWrapper>}
    </SectionTitleWrapper>
  );
};

SectionTitle.defaultProps = {
  className: undefined,
  titleWrapperClassName: undefined,
  link: undefined,
};

export default SectionTitle;
