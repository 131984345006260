import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import useTranslation, { getActiveLocale } from 'localizer';

import { useApi, PAGE_API } from 'api';
import { Page } from 'types';

import Container from 'components/UI/Container/Container';
import Loader from 'components/Loader/Loader';
import NotFound from 'container/404/404';
import { notification } from 'antd';
import SuccessIcon from 'components/Icons/SuccessIcon';
import ErrorIcon from 'components/Icons/ErrorIcon';
import PageWrapper from './Page.style';

const Component = (): ReactElement => {
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);
  const slug: string = location.pathname.substring(1).replace(`${locale}/`, '');
  const { data, loading: isLoading, error } = useApi<Page>(PAGE_API, {
    params: [slug],
  });

  const { t } = useTranslation();

  const showSuccessMessage = useCallback(
    (message: string): void => {
      notification.open({
        message: t('Success'),
        description: message,
        icon: <SuccessIcon />,
      });
    },
    [t],
  );

  const showErrorMessage = useCallback(
    (message: string): void => {
      notification.open({
        message: t('Error'),
        description: message,
        icon: <ErrorIcon />,
      });
    },
    [t],
  );

  const showMessage = useCallback(
    (message: string): void => {
      if (message !== '') {
        showSuccessMessage(message);
      } else {
        showErrorMessage('Error in saving message');
      }
    },
    [showSuccessMessage, showErrorMessage],
  );

  const [elementId, setElementId] = useState('');

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      setElementId(id);
    }
  }, []);

  useEffect(() => {
    let intervalId: number;
    if (elementId) {
      intervalId = setInterval(() => {
        const element = document.getElementById(elementId);
        if (element) {
          const yOffset = -80; // adjust this value to account for fixed headers or other elements
          const y =
            element.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
          clearInterval(intervalId);
        }
      }, 500);
    }
    return (): void => clearInterval(intervalId);
  }, [elementId]);

  if (error?.response?.status === 404) return <NotFound />;
  if (isLoading) return <Loader />;

  return (
    <PageWrapper>
      <Helmet>
        {data?.metaDescription && (
          <meta name="description" content={data?.metaDescription} />
        )}
        {data?.metaRobot && <meta name="robots" content={data?.metaRobot} />}
        {data?.metaKeyword && (
          <meta name="keywords" content={data?.metaKeyword} />
        )}
        {data?.richSnippets &&
        data?.richSnippets?.includes('application/ld+json') ? (
          <script type="application/ld+json">{`
        {
            ${data?.richSnippets?.slice(
              data?.richSnippets?.indexOf('{') + 1,
              data?.richSnippets?.lastIndexOf('}'),
            )}
        }
    `}</script>
        ) : (
          data?.richSnippets && parse(data?.richSnippets)
        )}
        {!isLoading && slug === 'business-solutions' && (
          <script
            src="https://kit.fontawesome.com/1e9a4b52fd.js"
            crossOrigin="anonymous"
          />
        )}
        <script type="text/javascript">
          {`
          function setNativeValue(element, value) {
            let lastValue = element.value;
            element.value = value ? value : '';
            let event = new Event("input", { target: element, bubbles: true });
            // React 15
            event.simulated = true;
            // React 16
            let tracker = element._valueTracker;
            if (tracker) {
                tracker.setValue(lastValue);
            }
            element.dispatchEvent(event);
          }

          async function postData(url = '', data = {}, language) {

            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                "Accept-Language": language
              },
              body: JSON.stringify(data)
            });
            return response.json();
          }

          function isEmailValid(input)
          {
            var mailformat = /^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/;
            return input.match(mailformat);
          }
          function isPhoneValid(input){
            var re = /^[\\+]?[(]?[0-9]{2,3}[)]?([-\\s\\.]?[0-9]{3,9})+$/im;
            return input.match(re);
          }
          function isURLValid(input){
            return input.match(/(http(s)?:\\/\\/.)(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
          }
          window.validateTalkToExpert = () => {
            document.getElementsByClassName('solution-talk-error')[0].classList.add('hidden');
            const emailOrPhone = document.getElementById('solution-talk-input').value;

            if(!isEmailValid(emailOrPhone) && !isPhoneValid(emailOrPhone)){
              document.getElementById('solution-talk-input').focus();
              document.getElementsByClassName('solution-talk-error')[0].classList.remove('hidden');
              return false;
            }
            return true;
          }
          window.submitTalkToExpert = (language)=>{
            const emailOrPhone = document.getElementById('solution-talk-input').value;
            if(!window.validateTalkToExpert()) return;

            let param = {
              "origin": "GHAT",
              "language": language
            };
            if(isEmailValid(emailOrPhone)){
              param = {
                "email_address": emailOrPhone,
                "origin": "GHAT",
                "language": language
              };
            }
            else{
              param = {
                "phone_number": emailOrPhone,
                "origin": "GHAT",
                "language": language
              };
            }

            document.getElementsByClassName('solution-talk-spinner')[0].classList.remove('hidden-important');
            postData('${process.env.REACT_APP_API_URL}api/talk-to-expert/', param, language)
              .then((data) => {
                document.getElementsByClassName('solution-talk-spinner')[0].classList.add('hidden-important');
                
                var inputElement = document.getElementById('result-messages');
                setNativeValue(inputElement, data.result);

                document.getElementById('solution-talk-input').value = '';
              });
          }
          window.validatePotentialCompany = () =>{
            document.getElementsByClassName('potential-company-error')[0].classList.add('hidden');
            const website = document.getElementById('potential-company-website').value;

            if(!isURLValid(website)){
              document.getElementById('potential-company-website').focus();
              document.getElementsByClassName('potential-company-error')[0].classList.remove('hidden');
              return false;
            }
            return true;
          }
          window.submitPotentialCompany = (language)=>{
            const website = document.getElementById('potential-company-website').value;
            if(!window.validatePotentialCompany()) return;

            let param = {
              "website": website
            };

            document.getElementsByClassName('potential-company-spinner')[0].classList.remove('hidden-important');
            postData('${process.env.REACT_APP_API_URL}api/potential-company/', param, language)
              .then((data) => {
                document.getElementsByClassName('potential-company-spinner')[0].classList.add('hidden-important');
                
                var inputElement = document.getElementById('result-messages');
                setNativeValue(inputElement, data.result);
                
                document.getElementById('potential-company-website').value = '';
              });
          }
          `}
        </script>
      </Helmet>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: data?.content ?? '' }} />
        <input
          id="result-messages"
          onChange={(event): void => showMessage(event.target.value)}
          type="text"
          className="hidden"
          value="-1"
        />
      </Container>
    </PageWrapper>
  );
};

export default Component;
