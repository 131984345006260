import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import {
  BUSINESS_FORM,
  CAREER_PAGE,
  FAQS_PAGE,
  HOME_PAGE,
  HOTEL_PARTNER,
  INFO_CENTER,
  INSIGHTS_PAGE,
  LOGIN_PAGE,
} from 'settings/constant';
import { useApi, PAGES_API } from 'api';
import type { Pages, Store } from 'types';
import useTranslation, { getActiveLocale } from 'localizer';
import { useLink } from 'utils';
import { setCookiePrefVisibility } from 'redux/actions/layout';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from './Footer.style';

const getStaticFooterLinks = (loggedIn: any): Array<Pages> => {
  return [
    {
      title: 'Home',
      slug: '',
      isSpecial: false,
      isPrivate: false,
    },
    {
      title: loggedIn ? 'FAQs' : 'Info Center',
      slug: loggedIn ? 'faqs' : 'info-center',
      isSpecial: false,
      isPrivate: false,
    },
    {
      title: 'Blogs',
      slug: 'blogs',
      isSpecial: false,
      isPrivate: false,
    },
    {
      title: 'Cookie Preferences',
      slug: '#',
      isSpecial: false,
      isPrivate: false,
    },
    {
      title: 'Become a Hotel Partner',
      slug: 'hotel-partner',
      isSpecial: false,
      isPrivate: false,
    },
    {
      title: 'Careers',
      slug: 'karriere',
      isSpecial: false,
      isPrivate: false,
    },
  ];
};

const FooterMenu = (): ReactElement => {
  const [defaultSelected, setDefaultSelected] = useState<string>('-1');
  const { t } = useTranslation();
  const { l } = useLink();
  const dispatch = useDispatch();
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);
  const loggedIn = useSelector((state: Store) => state.auth?.refreshToken);
  const history = useHistory();
  const currCookiePrefState = useSelector(
    (state: Store) => state.layout?.cookiePrefVisibility,
  );
  const { data, loading: isLoading, error } = useApi<Array<Pages>>(PAGES_API, {
    initialValue: [],
  });

  const handleSetCookiePref = (): void => {
    dispatch(setCookiePrefVisibility(!currCookiePrefState));
  };

  const allLinks: any = useMemo(() => [getStaticFooterLinks(loggedIn), data], [
    data,
    loggedIn,
  ]);
  let linksList: Array<any> = [];

  allLinks.forEach((element: Array<any>) => {
    element.forEach((el) => {
      linksList.push(el);
    });
  });
  linksList = linksList.filter((item) => !item.isSpecial);

  const privateList = linksList.filter((item) => item.isPrivate);
  useEffect(() => {
    privateList.forEach(
      (item) =>
        l(`/${item.slug}`) === location.pathname &&
        !loggedIn &&
        history.push(l(LOGIN_PAGE)),
    );
  }, [history, l, location.pathname, loggedIn, privateList]);

  if (!loggedIn) {
    linksList = linksList.filter((item) => !item.isPrivate);
  }
  useEffect(() => {
    setDefaultSelected('-1');
    if (location.pathname.includes(FAQS_PAGE)) {
      setDefaultSelected('faqs');
    } else if (location.pathname.includes(INFO_CENTER)) {
      setDefaultSelected('info-center');
    } else if (location.pathname === l(HOME_PAGE)) {
      setDefaultSelected('');
    } else if (location.pathname.includes(l(INSIGHTS_PAGE))) {
      setDefaultSelected('blogs');
    } else if (location.pathname.includes(l(HOTEL_PARTNER))) {
      if (!location.pathname.includes(l(BUSINESS_FORM))) {
        setDefaultSelected('hotel-partner');
      }
    } else if (location.pathname.includes(l(CAREER_PAGE))) {
      setDefaultSelected('karriere');
    } else if (allLinks) {
      const pageRoutes: Array<Pages> = allLinks?.filter(
        (each: Pages) => !each.isSpecial,
      );
      if (pageRoutes && pageRoutes.length > 0) {
        pageRoutes.forEach(
          ({ slug }) =>
            location.pathname.substring(1).replace(`${locale}/`, '') === slug &&
            setDefaultSelected(slug),
        );
      }
    }
    const activeLink = data?.filter((each: Pages) => !each.isSpecial);
    if (activeLink && activeLink.length > 0) {
      activeLink.forEach(
        ({ slug }) =>
          location.pathname.substring(1).replace(`${locale}/`, '') === slug &&
          setDefaultSelected(slug),
      );
    }
  }, [location.pathname, l, locale, allLinks, data, currCookiePrefState]);

  return (
    <>
      <Wrapper className="menu-width">
        <ul className="flex flex-wrap gap-x-1">
          {!isLoading && !error ? (
            <>
              {linksList.map(({ slug, title }: Pages) => (
                // eslint-disable-next-line react/no-array-index-key

                <li
                  key={`footer-menu-item${slug}`}
                  className="flex-grow h-full p-2 my-1 leading-4 sm:p-0 sm:w-1/3"
                >
                  <span key={slug}>
                    {slug !== '#' ? (
                      <NavLink
                        className={`footer-link ${
                          defaultSelected === slug ? 'active-footer-link' : ''
                        }`}
                        exact
                        to={l(`/${slug}`)}
                      >
                        {t(title)}
                      </NavLink>
                    ) : (
                      <Link
                        className="footer-link"
                        to="#/"
                        type="button"
                        onClick={handleSetCookiePref}
                      >
                        {t('Cookie Preferences')}
                      </Link>
                    )}
                  </span>
                </li>
              ))}
            </>
          ) : (
            <div className="ant-menu-item-group-list">
              <div className="menu_spin">
                <Spin size="large" />
              </div>
            </div>
          )}
        </ul>
      </Wrapper>
    </>
  );
};

export default memo(FooterMenu);
