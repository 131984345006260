import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useLink } from 'utils';
import LogoArea from './Logo.style';

type Props = {
  className?: string;
  withLink?: boolean;
  linkTo?: string;
  title?: string;
  src: string;
  imgStyles?: any;
  imgClassName?: string;
};

const Logo = ({
  className,
  withLink,
  linkTo,
  title,
  src,
  imgStyles,
  imgClassName,
}: Props): ReactElement => {
  const { l } = useLink();
  return (
    <LogoArea className={className}>
      {withLink && linkTo ? (
        <NavLink to={l(linkTo)}>
          {src && (
            <img
              style={imgStyles}
              src={src}
              alt="Green Habitat."
              className={imgClassName}
            />
          )}
          {title && <h3>{title}</h3>}
        </NavLink>
      ) : (
        <>
          {src && <img src={src} alt="Green Habitat." />}
          {title && <h3>{title}</h3>}
        </>
      )}
    </LogoArea>
  );
};

Logo.defaultProps = {
  className: undefined,
  withLink: undefined,
  linkTo: undefined,
  title: undefined,
  imgStyles: undefined,
  imgClassName: undefined,
};

export default Logo;
