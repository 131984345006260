import resolveConfig from 'tailwindcss/resolveConfig';
/* eslint-disable */
// @ts-ignore
import config from '../tailwind.config.js';
/* eslint-enable */

const useTheme = (): {
  colors: { gh: string; 'gh-hover': string; secondary: string };
} => {
  const fullConfig = resolveConfig(config);
  return fullConfig.theme;
};

export default useTheme;
