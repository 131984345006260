import React, {
  useEffect,
  useState,
  useRef,
  ReactElement,
  ReactChild,
} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

type Props = {
  className?: string;
  children?: Array<ReactChild>;
  showArrows?: boolean;
  interval?: number;
  autoPlay?: boolean;
  NextButton?: any;
  PrevButton?: any;
  stopOnHover?: boolean;
  showIndicators?: boolean;
};

const customRenderItem = (item: any, props: any): ReactElement => (
  // eslint-disable-next-line
  <item.type {...item.props} {...props} />
);

const GlideCarousel = ({
  className,
  children,
  showArrows,
  interval,
  autoPlay,
  NextButton,
  PrevButton,
  stopOnHover,
  showIndicators,
}: Props): ReactElement => {
  const [stateAutoPlay, setAutoPlay] = useState(autoPlay);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (!autoPlay && stateAutoPlay) {
      if (ref.current) ref.current.clearAutoPlay();
    } else if (autoPlay && !stateAutoPlay) {
      if (ref.current) {
        ref.current.autoPlay();
        ref.current.increment();
      }
    }
    setAutoPlay(autoPlay);
  }, [autoPlay, stateAutoPlay]);

  return (
    <>
      <Carousel
        ref={ref}
        className={`glide h-full ${className ?? ''}`}
        renderItem={customRenderItem}
        showArrows={showArrows}
        showStatus={false}
        infiniteLoop
        showThumbs={false}
        autoPlay
        stopOnHover={typeof stopOnHover !== 'undefined' ? stopOnHover : true}
        showIndicators={false} // remove indicators
        interval={10000}
        renderArrowPrev={
          PrevButton &&
          ((clickHandler: () => void, hasPrev: boolean): ReactElement | null =>
            hasPrev ? (
              <PrevButton
                onClick={clickHandler}
                className="carousel-arrow prev hover:bg-gh text-gh hover:text-white"
              />
            ) : null)
        }
        renderArrowNext={
          NextButton &&
          ((clickHandler: () => void, hasNext: boolean): ReactElement | null =>
            hasNext ? (
              <NextButton
                onClick={clickHandler}
                className="carousel-arrow next hover:bg-gh text-gh hover:text-white"
              />
            ) : null)
        }
      >
        {children}
      </Carousel>

      <style jsx global>
        {`
          .carousel-root .carousel {
            height: calc(100vh - 80px);
          }
          .carousel-root .carousel.carousel-slider {
            overflow: unset;
          }
          .carousel-root .control-dots .dot {
            border-radius: 8px;
            background-color: white;
            box-shadow: unset;
            opacity: 1;
          }
          .carousel-root .control-dots .dot.selected {
            width: 25px;
          }
          .carousel-root .slide iframe {
            width: 100%;
            margin: 0;
          }
          .carousel-root .slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
          .carousel-arrow {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
            position: absolute;
            top: calc(50% - 18px);
            border-radius: 50%;
            transition: background-color 0.2s ease 0s;
          }
          .carousel-arrow.prev {
            z-index: 1;
            left: -18px;
          }
          .carousel-arrow.next {
            z-index: 1;
            right: -18px;
          }
          @media (max-width: 767px) {
            .carousel-root .slide img {
              object-position: 70% top;
            }
          }
        `}
      </style>
    </>
  );
};

GlideCarousel.defaultProps = {
  className: undefined,
  children: undefined,
  showArrows: undefined,
  interval: undefined,
  autoPlay: undefined,
  NextButton: undefined,
  PrevButton: undefined,
  stopOnHover: undefined,
  showIndicators: undefined,
};

export default GlideCarousel;
