import {
  Action,
  SET_TOKEN,
  CLEAR_REFRESH_TOKEN,
  LOGOUT,
  SET_REMEMBER_ME,
} from '../types/auth';

export const setToken = (
  accessToken: string,
  refreshToken: string,
): Action => ({
  type: SET_TOKEN,
  accessToken,
  refreshToken,
});

export const clearRefreshToken = (): Action => ({
  type: CLEAR_REFRESH_TOKEN,
});

export const setRememberMe = (rememberMe: boolean): Action => ({
  type: SET_REMEMBER_ME,
  rememberMe,
});

export const logout = (message?: string, pathname?: string): Action => ({
  type: LOGOUT,
  message,
  pathname,
});
