import React, { ReactElement } from 'react';
import { Button } from 'antd';
import useTranslation from 'localizer';
import Box from 'components/UI/Box/Box';

type Props = {
  handleLoadMore: () => void;
  buttonText?: string;
  loading?: boolean;
  loadMoreComponent?: any;
  loadMoreStyle?: any;
};

const LoadMore = ({
  handleLoadMore,
  buttonText,
  loading,
  loadMoreComponent,
  loadMoreStyle,
}: Props): ReactElement => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line
    <Box className="loadmore_wrapper" {...loadMoreStyle}>
      {loadMoreComponent || (
        <Button loading={loading} onClick={handleLoadMore}>
          {buttonText ?? t('Load More')}
        </Button>
      )}
    </Box>
  );
};

LoadMore.defaultProps = {
  buttonText: undefined,
  loading: undefined,
  loadMoreComponent: undefined,
  loadMoreStyle: undefined,
};

export default LoadMore;
