import React, { useState, useEffect, ReactElement } from 'react';
import Sticky from 'react-stickynode';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Text from 'components/UI/Text/Text';
import Navbar from 'components/Navbar/Navbar';
import useWindowSize from 'library/hooks/useWindowSize';
import greenHabitatLogo from 'assets/images/logo-gh-green.svg';
import { Store, User } from 'types';
import { useSelector } from 'react-redux';
import { useLazyApi, PROFILE_API } from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

const avatarImg =
  'http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png';

const Header = (): ReactElement => {
  const isLoggedIn = !!useSelector((state: Store) => state.auth?.refreshToken);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const [user, setUser] = useState<User>();

  const sidebarHandler = (): void => {
    setState(!state);
  };
  const { load: getUser } = useLazyApi<User>(PROFILE_API, {
    withToken: true,
  });

  useEffect(() => {
    if (isLoggedIn) {
      getUser({}).then((res) => {
        setUser(res);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const getMinimizedName = (): string => {
    if (!user || !user.firstName || !user.lastName) return 'NA';
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  };
  let userPhoto = user?.photo;
  if (
    user?.photo &&
    process.env.REACT_APP_API_URL &&
    !user?.photo.includes(process.env.REACT_APP_API_URL)
  ) {
    userPhoto = process.env.REACT_APP_API_URL + user?.photo.substring(1);
  }
  return (
    <HeaderWrapper>
      <Sticky
        top={0}
        innerZ={10001}
        activeClass="isHeaderSticky"
        innerClass="filterbar-line"
      >
        {width && width > 991 ? (
          <Navbar
            logo={
              <Logo
                withLink
                linkTo="/"
                src={greenHabitatLogo}
                imgStyles={{ height: 44 }}
              />
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={isLoggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={
              <ProfileMenu
                userFirstName={user ? user.firstName : ''}
                isLoggedIn={isLoggedIn}
              />
            }
            searchComponent={<NavbarSearch />}
          />
        ) : (
          <MobileNavbar className="default is_default">
            <LogoArea>
              <Logo
                withLink
                linkTo="/"
                src={greenHabitatLogo}
                imgStyles={{ height: 44 }}
              />
              <NavbarSearch />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button type="button" onClick={sidebarHandler}>
                  <FontAwesomeIcon
                    icon={['fal', 'times']}
                    className="text-xl"
                  />
                </button>
              </CloseDrawer>
              {isLoggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    {user?.photo && <img src={userPhoto} alt="user-profile" />}
                    {!user?.photo && (
                      <div className="flex items-center justify-center w-full h-full tracking-widest text-white bg-gray-700">
                        {getMinimizedName()}
                      </div>
                    )}
                  </AvatarImage>
                  <AvatarInfo>
                    <Text
                      as="h3"
                      content={`${user?.firstName ?? ''} ${
                        user?.lastName ?? ''
                      }`}
                    />
                    {/* <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content={t('View Profile')}
                      icon={undefined}
                    /> */}
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu />
              )}

              <MobileMenu className="main-menu" onClose={sidebarHandler} />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
};

export default Header;
