import {
  Action,
  State,
  SET_TOKEN,
  CLEAR_REFRESH_TOKEN,
  LOGOUT,
  SET_REMEMBER_ME,
} from '../types/auth';

const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
  message: undefined,
  pathname: undefined,
  rememberMe: false,
};

export default (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        message: '',
      };
    case CLEAR_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: undefined,
      };
    case SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.rememberMe,
      };
    case LOGOUT:
      return {
        ...state,
        accessToken: undefined,
        refreshToken: undefined,
        message: action.message,
        pathname: action.pathname,
      };
    default:
      return state;
  }
};
