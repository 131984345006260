import { useCallback } from 'react';
import { useTranslation as useTranslationI18n, Namespace } from 'react-i18next';
import { TFunction, i18n as i18nType } from 'i18next';

const useTranslation = (ns?: Namespace): { t: TFunction; i18n: i18nType } => {
  const { t, i18n, ready } = useTranslationI18n(ns, { useSuspense: false });
  const translate = useCallback((_: string): string => '', []);
  return ready ? { t, i18n } : { t: translate, i18n };
};

export default useTranslation;
