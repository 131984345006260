// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';
export const PAGES = '/';

// Hotels Single Page Section
export const HOTELS_POSTS_PAGE = '/hotels';
export const SINGLE_POST_PAGE = '/hotels';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
// export const AGENT_NEWSLETTER_PAGE = '/newsletter';
export const AGENT_FINANCIAL_EDIT_PAGE = '/edit-financial';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVORITE = '/favorite-post';
export const AGENT_PROFILE_CONTACT = '/contact';
export const AGENT_ACCOUNT_BOOKING_REQUESTS = '/my-bookings';

// Other Pages
export const PRIVACY_PAGE = '/privacy';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const VERIFY_REGISTRATION_PAGE = '/verify-email';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

// Destinations / Regions
export const DESTINATIONS_PAGE = '/destinationen';

// Attractions
export const ATTRACTIONS_PAGE = '/attractions';

// Offers
export const OFFERS_PAGE = '/offers';
// Local Businesses
export const LOCAL_BUSINESSES_PAGE = '/local-businesses';
export const CONTACT_PAGE = '/contact';
export const ABOUT_PAGE = '/about';
// Golf COurse
export const GOLF_COURSE_PAGE = '/golfplaetze';
export const SEARCH_ENTITY_PAGE = '/search';
// FAQs
export const FAQS_PAGE = '/faqs';
// Region
export const REGIONS = '/regions';
// Blogs
export const INSIGHTS_PAGE = '/blogs';
// Hotel partner
export const HOTEL_PARTNER = '/hotel-partner';
// Impressum
export const IMPRESSUM = '/impressum';
// Vouchers
export const VOUCHERS_PAGE = '/vouchers';
// Career
export const CAREER_PAGE = '/karriere';
// Green Badge
export const GREEN_BADGE = '/green-badge';
export const GREEN_SOLUTION = '/business-solutions';
// Newsletter
export const VERIFY_NEWSLETTER = '/verify-newsletter';

// Info Center
export const INFO_CENTER = '/info-center';

// Code Request
export const CODE_REQUEST = '/code-request';

// Business Form
export const BUSINESS_FORM = '/hotel-partner-info/';
export const BUSINESS_FORM_API = '/api/partner-form/';
