import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useLink } from 'utils';

type Props = {
  link: string;
  icon?: ReactElement;
  content: string;
  className?: string;
};

const TextLink = ({ link, icon, content, className }: Props): ReactElement => {
  const { l } = useLink();
  return (
    <Link to={l(link)} className={className}>
      {icon} {content}
    </Link>
  );
};

TextLink.defaultProps = {
  icon: undefined,
  className: undefined,
};

export default TextLink;
