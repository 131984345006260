import styled from 'styled-components';

const Wrapper = styled.div`
  .ant-menu-item {
    height: 20px;
    line-height: 20px;
  }
  .ant-menu-item-group-title,
  .ant-menu-item-group-title {
    display: none;
  }

  .active-footer-link {
    font-weight: 900 !important;
    color: black !important;
  }

  .footer-link:hover {
    font-weight: 900 !important;
    color: black !important;
  }
`;

export default Wrapper;
