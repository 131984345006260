import React, { ReactElement } from 'react';
import useTranslation from 'localizer';
import Image from 'components/UI/Image/Image';
import Heading from 'components/UI/Heading/Heading';
import TextLink from 'components/UI/TextLink/TextLink';
import Image404 from 'assets/images/404.png';
import NotFoundWrapper, { ContentWrapper } from './404.style';

type Props = {
  staticContext?: { status?: number };
};

const NotFound = ({
  staticContext = { status: undefined },
}: Props): ReactElement => {
  staticContext.status = 404;
  const { t } = useTranslation();
  return (
    <NotFoundWrapper>
      <ContentWrapper>
        <Image src={Image404} alt="404" />
        <Heading as="h2" content={t('Something Went Wrong')} />
        <TextLink
          link="/"
          content={t('Go Back')}
          className="rounded-full hover:opacity-90"
        />
      </ContentWrapper>
    </NotFoundWrapper>
  );
};

NotFound.defaultProps = {
  staticContext: undefined,
};

export default NotFound;
