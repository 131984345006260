import { library } from '@fortawesome/fontawesome-svg-core';
// fal
import {
  faGlobe,
  faTimesCircle,
  faMinus,
  faPlus,
  faUserPlus,
  faTimes,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faHistory,
  faSmoking,
  faGarage,
  faTruckPickup,
  faSearch as falFaSearch,
  faExclamationCircle,
  faTags,
  faFileCertificate,
  faGolfClub,
  faHotel,
  faWaveSine,
  faCalendarEdit,
  faChevronDown as falChevDown,
  faChevronUp as falChevUp,
  faHandHoldingSeedling,

  // Activities
  faSpa,
  faMusicAlt,
  faMusic,
  faSpeakers,
  faGlassCheers,
  faWineBottle,
  faClub,
  faDemocrat,
  faUnicorn,
  faGamepad,
  faSmile,
  faGrinBeam,
  faChild,
  faUsers,
  faBus,
  faShuttleVan,
  faShoppingCart,
  faCameraRetro,
  faMountain,
  faTree,
  faTrees,
  faLeaf,
  faFlowerTulip,
  faFlowerDaffodil,
  faShip,
  faWhale,
  faIslandTropical,
  faWater,
  faUmbrellaBeach,
  faSwimmer,
  faSwimmingPool,
  faHotTub,
  faHorse,
  faHiking,
  faDumbbell,
  faBallPile,
  faBullseyeArrow,
  faGolfBall,
  faBowlingPins,
  faBasketballBall,
  faBiking,
  faBikingMountain,
  faBicycle,
  faSkiLift,
  faSkating,
  faSkiJump,
  faSkiing,
  faSkiingNordic,
  faSledding,
  faSnowboarding,
  faFootballBall,
  faFutbol,
  faRacquet,
  faTableTennis,
  faBaseball,
  faVolleyballBall,
  faHockeySticks,
  faCity,
  faTheaterMasks,
  faWalking,
  faFish,
  faImagePolaroid,
  faTennisBall,
  faPrayingHands,
  faMotorcycle,
  faCurling,
  // Amenities
  faWind,
  faToiletPaperAlt,
  faBath,
  faToilet,
  faShower,
  faDoorClosed,
  faOutlet,
  faPaw,
  faCouch,
  faTv,
  faRadio,
  faPhoneRotary,
  faCookie,
  faAppleAlt,
  faWineGlassAlt,
  faSalad,
  faBurgerSoda,
  faTicket,
  faFileInvoiceDollar,
  faLuggageCart,
  faPlaneAlt,
  faTvRetro,
  faVacuum,
  faFireExtinguisher,
  faSensorSmoke,
  faSmokingBan,
  faStars,
  faLock,
  faHeat,
  faRectanglePortrait,
  faNewspaper,
  faBedAlt,
  faBed,
  faWindowFrame,
  faSmileBeam,
  faScroll,
  faNetworkWired,
  faWasher,
  faBuilding,
  faPodium,
  faLampDesk,
  faAlarmClock,
  faWheelchair,
  faTshirt,
  faPortalExit,
  faFirstAid,
  faSun,
  faSprinkler,
  faHatChef,
  faRefrigerator,
  faCoffeePot,
  faPumpSoap,
  faLanguage,
  faBookReader,
  faFanTable,
  faWalker,
  faSortCircleUp,
  faLaptop,
  faVolumeOff,
  faSirenOn,
  faCctv,
  faUtensilsAlt,
  faChargingStation,
  faPresentation,
  faRingsWedding,
  faConciergeBell,
  faUserFriends,
  faWifi,
  faParkingCircle,
  faEggFried,
  faMugHot,
  faBriefcase,
  faDryer,
  faAirConditioner,
  faCreditCard,
  faGarageCar,
  faMoneyCheckAlt,
  faDice,
  faSeedling,
  faCheckCircle,
  faDog,
  faAllergies,
  faUserAlt,
  faHandsWash,
  faSolarPanel,
  faUserGraduate,
  faRecycle,
  faGlass,
  faShoppingBag,
  faInfoCircle,
  faBoot,
  faAngleLeft,
  faFilePdf,
  faHandHoldingHeart,
  faCheck as faCheckLight,
  faPen,
} from '@fortawesome/pro-light-svg-icons';
// far
import {
  faCheckCircle as farFaCheckCircle,
  faPhone,
  faLockOpenAlt,
  faStar as farFaStar,
  faHorizontalRule,
  faCalendarCheck,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes as faTimesRegular,
} from '@fortawesome/pro-regular-svg-icons';
// fas
import {
  faTh,
  faEye,
  faPlusCircle,
  faChevronDown,
  faStar,
  faUserCircle,
  faCamera,
  faListUl,
  faCalendar,
  // contact
  faEnvelope,
  faAt,
  faPhoneAlt,
  faPhoneVolume,
  faMobile,
  faFax,
  faMapMarkerAlt,
  faComment,
  faUserHeadset,
  faCog,
  faFileUpload,
  faMountains,
  faLeaf as faFalLeaf,
  faCrown,
  faMapMarkedAlt,
  faMapMarkerAltSlash,
  faMap,
  faPennant,
  faGift as faGiftSolid,
  faCheck,
  faBell,
} from '@fortawesome/pro-solid-svg-icons';
// fab
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  // contact
  faInstagramSquare,
  faFacebook,
  faTwitterSquare,
  faYoutube,
  faLinkedin,
  faPinterest,
  faWhatsappSquare,
  faTelegram,
  faEnvira,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  // fal
  faTimesCircle,
  faMinus,
  faPlus,
  faUserPlus,
  faTimes,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faExclamationCircle,
  faHistory,
  falFaSearch,
  faSmoking,
  faGarage,
  faTruckPickup,
  faTags,
  faFileCertificate,
  faGolfClub,
  faHotel,
  faMountains,
  faWaveSine,
  faCalendarEdit,
  falChevDown,
  falChevUp,
  faUserAlt,

  // far
  faTh,
  farFaCheckCircle,
  faPhone,
  faLockOpenAlt,
  farFaStar,
  faHorizontalRule,
  faCalendarCheck,
  // fas
  faEye,
  faPlusCircle,
  faChevronDown,
  faStar,
  faUserCircle,
  faCamera,
  faListUl,
  faCalendar,
  faFileUpload,
  faMountains,
  faCrown,
  // fab
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  // Green Features fal
  faSalad,
  faGlass,
  faSeedling,
  faHandsWash,
  faSolarPanel,
  faChargingStation,
  faBedAlt,
  faGlobe,
  faUserGraduate,
  faRecycle,
  faBicycle,
  faUmbrellaBeach,
  faShoppingBag,
  // Activities fal
  faSpa,
  faMusicAlt,
  faMusic,
  faSpeakers,
  faGlassCheers,
  faWineBottle,
  faClub,
  faDemocrat,
  faUnicorn,
  faGamepad,
  faSmile,
  faGrinBeam,
  faChild,
  faUsers,
  faBus,
  faShuttleVan,
  faShoppingCart,
  faCameraRetro,
  faMountains,
  faMountain,
  faTree,
  faTrees,
  faLeaf,
  faFlowerTulip,
  faFlowerDaffodil,
  faShip,
  faWhale,
  faIslandTropical,
  faWater,
  faUmbrellaBeach,
  faSwimmer,
  faSwimmingPool,
  faHotTub,
  faHorse,
  faHiking,
  faDumbbell,
  faBallPile,
  faBullseyeArrow,
  faGolfBall,
  faBowlingPins,
  faBasketballBall,
  faBiking,
  faBikingMountain,
  faBicycle,
  faSkiLift,
  faSkating,
  faSkiJump,
  faSkiing,
  faSkiingNordic,
  faSledding,
  faSnowboarding,
  faFootballBall,
  faFutbol,
  faRacquet,
  faTableTennis,
  faBaseball,
  faVolleyballBall,
  faHockeySticks,
  faCity,
  faTheaterMasks,
  faWalking,
  faFish,
  faImagePolaroid,
  faTennisBall,
  faPrayingHands,
  faMotorcycle,
  faCurling,
  // Amenities fal
  faWind,
  faToiletPaperAlt,
  faBath,
  faToilet,
  faShower,
  faDoorClosed,
  faOutlet,
  faPaw,
  faCouch,
  faTv,
  faRadio,
  faPhoneRotary,
  faCookie,
  faAppleAlt,
  faWineGlassAlt,
  faSalad,
  faBurgerSoda,
  faTicket,
  faFileInvoiceDollar,
  faLuggageCart,
  faPlaneAlt,
  faTvRetro,
  faVacuum,
  faFireExtinguisher,
  faSensorSmoke,
  faSmokingBan,
  faStars,
  faLock,
  faHeat,
  faRectanglePortrait,
  faNewspaper,
  faBedAlt,
  faBed,
  faWindowFrame,
  faSmileBeam,
  faScroll,
  faNetworkWired,
  faWasher,
  faBuilding,
  faPodium,
  faLampDesk,
  faAlarmClock,
  faWheelchair,
  faTshirt,
  faPortalExit,
  faFirstAid,
  faSun,
  faSprinkler,
  faHatChef,
  faRefrigerator,
  faCoffeePot,
  faPumpSoap,
  faLanguage,
  faBookReader,
  faFanTable,
  faWalker,
  faSortCircleUp,
  faLaptop,
  faVolumeOff,
  faSirenOn,
  faCctv,
  faUtensilsAlt,
  faChargingStation,
  faPresentation,
  faRingsWedding,
  faConciergeBell,
  faUserFriends,
  faWifi,
  faParkingCircle,
  faEggFried,
  faMugHot,
  faBriefcase,
  faDryer,
  faAirConditioner,
  faCreditCard,
  faGarageCar,
  faMoneyCheckAlt,
  faDice,
  faSeedling,
  faCheckCircle,
  faDog,
  faAllergies,
  faCheckCircle,
  faCog,
  // contact fas
  faEnvelope,
  faAt,
  faPhoneAlt,
  faPhoneVolume,
  faMobile,
  faFax,
  faGlobe,
  faMapMarkerAlt,
  faComment,
  faUserHeadset,
  // contact fab
  faInstagramSquare,
  faFacebook,
  faTwitterSquare,
  faYoutube,
  faLinkedin,
  faPinterest,
  faWhatsappSquare,
  faTelegram,
  faEnvira,
  faInfoCircle,
  faBoot,
  faFalLeaf,
  faMapMarkedAlt,
  faMapMarkerAltSlash,
  faMap,
  faFlowerDaffodil,
  faWalking,
  faHandHoldingSeedling,
  faPennant,
  faAngleLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
  faFilePdf,
  faTh,
  faHandHoldingHeart,
  faGiftSolid,
  faCheck,
  faTimesRegular,
  faCheckLight,
  faBell,
  faTimes,
  faPen,
);
