import React, { ReactElement } from 'react';
import Loader from 'components/Loader/Loader';
import Container from 'components/UI/Container/Container';
import Heading from 'components/UI/Heading/Heading';
import TextLink from 'components/UI/TextLink/TextLink';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import GlideCarousel from 'components/UI/GlideCarousel/GlideCarousel';
import { DESTINATIONS_PAGE } from 'settings/constant';
import { useApi, DESTINATIONS_API } from 'api';
import useTranslation from 'localizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DestinationList } from 'types/Destination';
import LocationWrapper, { CarouselSection } from './Location.style';

const carouselOptions = {
  type: 'carousel',
  perView: 5,
  gap: 30,
  hoverpause: true,
  breakpoints: {
    1440: {
      perView: 5,
      gap: 20,
    },
    1200: {
      perView: 5,
    },
    991: {
      perView: 4,
      gap: 15,
    },
    767: {
      perView: 2,
      gap: 20,
    },
    480: {
      perView: 1,
      gap: 0,
    },
  },
};

const LocationGrid = (): ReactElement => {
  const { t } = useTranslation();

  const API_URL = `${DESTINATIONS_API}?format=json`;
  const { data } = useApi<DestinationList>(API_URL, {
    initialValue: { filterbar: [], destinations: [] },
  });

  return (
    <LocationWrapper>
      <Container fluid>
        <SectionTitle
          className="wrapper-horizontal landing-wrapper-header"
          title={
            <Heading content={t('Explore Destinations')} className="text-gh" />
          }
          link={
            <TextLink
              icon={undefined}
              link={DESTINATIONS_PAGE}
              content={t('Show all')}
            />
          }
        />

        <CarouselSection className="wrapper-fixed landing-wrapper-header">
          {data?.destinations?.length !== 0 ? (
            <GlideCarousel
              carouselSelector="explore_carousel"
              prevButton={<FontAwesomeIcon icon={['fal', 'chevron-left']} />}
              nextButton={<FontAwesomeIcon icon={['fal', 'chevron-right']} />}
              options={carouselOptions}
              data={data?.destinations}
            />
          ) : (
            <Loader />
          )}
        </CarouselSection>
      </Container>
    </LocationWrapper>
  );
};

export default LocationGrid;
