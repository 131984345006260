import { useMemo } from 'react';
import { AUSTRIAN } from 'settings/locales';
import { useLocation } from 'react-router-dom';
import { getActiveLocale } from 'localizer';

const useLink = (): { l: (link: string) => string } => {
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);
  const l = useMemo(
    () => (link: string): string => {
      return `${locale === AUSTRIAN ? '' : `/${locale}`}${link}`;
    },
    [locale],
  );
  return { l };
};

export default useLink;
