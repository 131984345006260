import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import PlaceHolderImage from 'assets/location-placeholder.jpg';
import Image from 'components/Image';
import { useLink } from 'utils';

import ImageCardWrapper, { Title, Meta, CountryName } from './ImageCard.style';

type Props = {
  className?: string;
  imageSrc: string;
  title?: string;
  country?: string;
  link: string;
  meta?: string;
  imgClassName?: string;
  fallbackImage?: string;
};

const ImageCard = ({
  className,
  imageSrc,
  title,
  country,
  link,
  meta,
  imgClassName,
  fallbackImage,
}: Props): ReactElement => {
  // const [isFailed, setImageIsFailed] = useState(false);
  // Add all classes to an array
  const addAllClasses = ['image_card'];
  const { l } = useLink();

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <>
      <ImageCardWrapper className={addAllClasses.join(' ')}>
        <Link to={l(link)}>
          <Image
            src={imageSrc}
            alt={title}
            className={imgClassName}
            fallbackImage={PlaceHolderImage}
            // callBackError={(isImageFailed: boolean): void =>
            //   setImageIsFailed(isImageFailed)
            // }
          />
        </Link>
        <CountryName>{country}</CountryName>

        {meta && <Meta>{meta}</Meta>}
        {/* <ContentWrapper>
        {title && isFailed ? (
          <Title>{title}</Title>
        ) : (
          <BackgroundTitle>
            <Title>{title}</Title>
          </BackgroundTitle>
        )}
        {meta && <Meta>{meta}</Meta>}
      </ContentWrapper> */}
      </ImageCardWrapper>
      <Link to={l(link)}>
        <Title>{title}</Title>{' '}
      </Link>
    </>
  );
};

ImageCard.defaultProps = {
  className: undefined,
  title: undefined,
  country: undefined,
  meta: undefined,
  imgClassName: undefined,
  fallbackImage: undefined,
};

export default ImageCard;
