import React, { useState, ReactElement } from 'react';
import useWindowSize from 'library/hooks/useWindowSize';
import { getActiveLocale } from 'localizer';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LocalePortal, { getFlag } from './LocalePortal';

type Props = { isLogin: boolean };
const Flag = ({ isLogin }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const locale = getActiveLocale(location.pathname);
  const { width } = useWindowSize();

  return (
    <div className={isLogin ? 'pt-2' : ''}>
      <a
        href="#lang"
        style={{
          // width: 180,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingRight: '8px',
        }}
        onClick={(): void => setIsOpen(!isOpen)}
      >
        <div
          style={{
            width: 20,
            height: 20,
            marginLeft: width && width <= 1100 ? 12 : '',
            marginRight: width && width > 1200 ? 12 : 8,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${getFlag(locale)})`,
          }}
        />
      </a>
      <LocalePortal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Helmet htmlAttributes={{ lang: locale }} />
    </div>
  );
};

export default Flag;
