import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'assets/style/Global.style';
import { store, persistor } from 'redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Routes from './router';
import './App.generated.css';
import 'localizer/configure';
import './fontawesome';

const App = (): ReactElement => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <BrowserRouter>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </BrowserRouter>
        </>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
