import React from 'react';
import Heading from 'components/UI/Heading/Heading';
import TextLink from 'components/UI/TextLink/TextLink';
import Container from 'components/UI/Container/Container';
import SectionGrid from 'components/SectionGrid/SectionGrid';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import useWindowSize from 'library/hooks/useWindowSize';
import { useApi, RECOMMENDATION_HOTELS } from 'api';
import type { Store, HotelResponse } from 'types';
import { HOTELS_POSTS_PAGE } from 'settings/constant';
import useTranslation from 'localizer';
import { useSelector } from 'react-redux';

const TopHotelsGrid = () => {
  const { t } = useTranslation();
  const accessToken = useSelector((state: Store) => state.auth?.accessToken);
  const { data: loadedData, loading, reload } = useApi<HotelResponse>(
    RECOMMENDATION_HOTELS,
    {
      initialValue: { hotels: [], filterbar: [] },
      withToken: (accessToken && accessToken.length > 0) || false,
    },
  );

  const { width } = useWindowSize();

  const posts = loadedData?.hotels ?? [];
  let limit;

  if (posts && width && width <= 767) {
    limit = 4;
  }
  if (posts && width && width >= 768) {
    limit = 6;
  }
  if (posts && width && width >= 992) {
    limit = 6;
  }
  if (posts && width && width >= 1200) {
    limit = 8;
  }

  return (
    <Container fluid>
      <SectionTitle
        className="wrapper-horizontal landing-wrapper-header"
        title={<Heading content={t('Highlights')} className="text-gh" />}
        link={
          <TextLink
            icon={undefined}
            link={HOTELS_POSTS_PAGE}
            content={t('Show all')}
          />
        }
      />
      <div className="wrapper-fixed landing-wrapper-header">
        {posts && posts.length > 0 && (
          <SectionGrid
            truncate
            data={posts}
            loading={loading}
            limit={limit}
            totalItem={posts.length}
            reload={reload}
          />
        )}
      </div>
    </Container>
  );
};

export default TopHotelsGrid;
