import React, { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import { SINGLE_POST_PAGE } from 'settings/constant';
import urlUtils from 'utils/url';
import { getActiveLocale } from 'localizer';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import InfoCenterLink from 'components/InfoCenter/InfoCenterLink';
import FRCHModal from 'components/FRCH/FRCHModal';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Cookie from './Cookie/Cookie';

const { Content } = LayoutWrapper;

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props): ReactElement => {
  const { width } = useWindowSize();
  const singlePageUrlFromConst = SINGLE_POST_PAGE.split('/');
  const location = useLocation();
  const singlePageUrlFormLocation = location.pathname.split('/');
  const locale = getActiveLocale(location.pathname);

  // Cookies
  const isCookieAllowed = localStorage.getItem('extended-cookies');

  // Implementing Google analytic V4
  // const { data } = useApi<Google>(GOOGLE_ANALYTIC_API);
  // const mID = data?.value ?? process.env.REACT_APP_GOOGLE_MEASURMENT_ID;

  const gTags = (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id="${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V1}"`}
      />
      <script type="text/javascript">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){
          dataLayer.push(arguments);
         } 
         gtag('js', new Date());
         gtag('config', "${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V1}");
        (function(){
          var disableStr = 'ga-disable-"${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V1}"';

          // Disable tracking if the opt-out cookie exists.
          if (document.cookie.indexOf(disableStr + '=true') > -1) {
            window[disableStr] = true;
          }
          
          
          // Opt-out function
          window.gaOptout1 = ()=> {
            document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
            window[disableStr] = true;
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            } 
            gtag('js', new Date());
            gtag('config', "${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V1}", {'anonymize_ip': true});
          }
          window.gaOptout = ()=>{
            gaOptout1();
            gaOptout2();
          }
          }())

          `}
      </script>

      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id="${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V2}"`}
      />
      <script type="text/javascript">
        {` 
         window.dataLayer = window.dataLayer || [];
         function gtag(){
         dataLayer.push(arguments);
            } 
         gtag('js', new Date());
         gtag('config', "${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V2}");
        // Disable tracking if the opt-out cookie exists.
          
          (function(){

            var disableStr = 'ga-disable-"${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V2}"';
            if (document.cookie.indexOf(disableStr + '=true') > -1) {
              window[disableStr] = true;
            }
   
            // Opt-out function
            window.gaOptout2 = ()=> {
              document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
              window[disableStr] = true;
              window.dataLayer = window.dataLayer || [];
              function gtag(){
                dataLayer.push(arguments);
              } 
              gtag('js', new Date());
              gtag('config', "${process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V2}", {'anonymize_ip': true});
            }}())
              `}
      </script>
    </Helmet>
  );

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
      key="layout-google-recaptcha-key"
    >
      {isCookieAllowed === 'true' && gTags}
      {urlUtils.isCompletePage(location.pathname, locale) ? (
        <Content>{children}</Content>
      ) : (
        <>
          <Header />
          <Content>{children}</Content>
          <FRCHModal />
          <InfoCenterLink />
          <Footer />
          {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
            <>{width && width < 1200 && <div style={{ height: '74px' }} />}</>
          )}
        </>
      )}
      <Cookie key="layout-cookie" />
    </GoogleReCaptchaProvider>
  );
};

export default Layout;
