import React, { ReactElement, ReactNode } from 'react';
import ContainerWrapper from './Container.style';

const Container = ({
  children,
  className,
  fullWidth,
  noGutter,
  fluid,
}: {
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  noGutter?: boolean;
  fluid?: boolean;
}): ReactElement => {
  return (
    <ContainerWrapper
      className={className}
      fullWidth={fullWidth}
      noGutter={noGutter}
      fluid={fluid}
    >
      {children}
    </ContainerWrapper>
  );
};

Container.defaultProps = {
  children: undefined,
  className: undefined,
  fullWidth: undefined,
  noGutter: undefined,
  fluid: false,
};

export default Container;
