import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const NavbarWrapper = styled.nav`
  display: none;
  width: 100%;
  min-height: 82px;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid ${themeGet('border.3', '#E6E6E6')};
  background-color: ${themeGet('color.1', '#ffffff')};
  @media only screen and (min-width: 991px) {
    display: flex;
  }

  &.is_transparent {
    background: transparent;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    border-bottom: 0;
    background-color: transparent;
    transition: all 0.3s ease-out;

    .ant-menu {
      li {
        &.ant-menu-item-selected {
          background-color: transparent;
        }
        a {
          color: ${themeGet('color.1', '#ffffff')};
          &.active {
            border-bottom: 0;
          }
        }
      }
      &.dropdown-menu {
        li {
          a {
            color: ${themeGet('text.0', '#2C2C2C')};
          }
        }
      }
    }
    .auth_menu {
      a:first-child {
        color: white;
      }
      a:first-child:hover {
        color: ${themeGet('primary.0')};
      }
      a:last-child {
        padding-top: 0.5rem;
        padding-bottom: 0.625rem;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;
  max-width: 370px;
  width: 100%;
  > a {
    margin-right: 27px;
    height: 80px;
  }
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  .avatar-dropdown {
    position: relative;

    .dropdown-handler {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 0;
      top: 45px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        color: ${themeGet('text.0', '#2C2C2C')};
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        height: auto;
        padding: 0;
        transition: color 0.2s ease-in-out;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          display: block;
          padding: 8px 16px;
          color: ${themeGet('text.0', '#2C2C2C')};
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet('primary.0', '#008489')};
          }
          &.active {
            font-weight: 500;
            color: ${themeGet('primary.0', '#008489')};
          }
        }
        button {
          padding: 0;
          border: 0;
          cursor: pointer;
          padding: 8px 16px;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet('primary.0', '#008489')};
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    li {
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      line-height: 18px;
      font-weight: 600;
      transition: color 0.2s ease-in-out;
      margin-top: 0;
      @media only screen and (max-width: 1200px) {
        margin: 0 10px;
      }
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        background-color: transparent;
      }

      a {
        display: block;
        padding: 30px 16px;
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        @media only screen and (max-width: 1200px) {
          padding: 30px 10px;
        }
        &:hover {
          color: ${themeGet('primary.0', '#008489')};
        }
        &.active {
          font-weight: 500;
          color: ${themeGet('primary.0', '#008489')};
          border-bottom: 3px solid ${themeGet('primary.0', '#008489')};
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    ul li:not(:first-child),
    .ant-menu li:not(:first-child),
    ul.ant-menu li:not(:first-child) {
      margin: 0 !important;
    }
    ul li:first-child,
    .ant-menu li:first-child,
    ul.ant-menu li:first-child {
      margin: 0 0 0 10px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    ul li.lang-item,
    .ant-menu li.lang-item,
    ul.ant-menu li.lang-item {
      margin: 0 0 0 0px !important;
    }
  }
`;

export const AuthWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    li {
      margin: 0;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      transition: color 0.2s ease-in-out;
      &.ant-menu-item-selected {
        background-color: transparent;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 78px;
        min-height: 38px;
        border-radius: 3px;
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#008489')};
        }
      }

      &:last-child {
        a {
          color: ${themeGet('color.1', '#ffffff')};
          background-color: ${themeGet('primary.0', '#008489')};
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
`;

export default NavbarWrapper;
