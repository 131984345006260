import React, { ReactElement, ReactNode } from 'react';
import NavbarWrapper, {
  Container,
  LogoArea,
  MenuArea,
  AvatarWrapper,
  AuthWrapper,
  MenuWrapper,
} from './Navbar.style';

type Props = {
  className?: string;
  logo: ReactNode;
  avatar: ReactNode;
  navMenu: ReactNode;
  authMenu: ReactNode;
  profileMenu: ReactNode;
  isLogin: boolean;
  searchComponent: ReactNode;
};

const Navbar = ({
  className,
  logo,
  avatar,
  navMenu,
  authMenu,
  profileMenu,
  isLogin,
  searchComponent,
}: Props): ReactElement => {
  return (
    <NavbarWrapper
      className={`navbar ${className ?? ''} is_default wrapper-horizontal py-0`}
    >
      <Container>
        {logo ? (
          <LogoArea>
            {logo}
            {searchComponent}
          </LogoArea>
        ) : null}
        <MenuArea className="flex flex-1 xl:pl-4">
          {navMenu && (
            <MenuWrapper className="flex-1 main_menu">{navMenu}</MenuWrapper>
          )}
          {isLogin && avatar ? (
            <AvatarWrapper>{profileMenu}</AvatarWrapper>
          ) : (
            authMenu && (
              <AuthWrapper className="auth_menu">{authMenu}</AuthWrapper>
            )
          )}
        </MenuArea>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.defaultProps = {
  className: undefined,
};

export default Navbar;
