import { useState, useCallback } from 'react';
import { Store } from 'types';
import useTranslation, { getActiveLocale, BRITISH } from 'localizer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { LOGIN_PAGE } from 'settings/constant';
import { setToken, logout } from 'redux/actions/auth';
import { useLink } from 'utils';
import { toCamelCase } from './useApi';

const REFRESH_TOKEN_API = 'api/token/refresh/';
const TOKEN_API = 'api/token/?format=json';

type Methods = 'get' | 'post' | 'patch' | 'put';

const useLazyApi = <T>(
  api: string,
  options?: {
    params?: Array<string>;
    method?: Methods;
    headers?: Record<string, string>;
    body?: string | FormData;
    cache?: RequestCache;
    withToken?: boolean;
    initialValue?: T;
    responseType?: 'json' | 'blob' | 'arraybuffer';
  },
): {
  load: (options: {
    params?: Array<string>;
    initialValue?: T;
    method?: Methods;
    headers?: Record<string, string>;
    body?: string | FormData;
    cache?: RequestCache;
  }) => Promise<T>;
  data: T | undefined;
  loading: boolean;
  error: Error | undefined;
  response: AxiosResponse | undefined;
} => {
  const { l } = useLink();
  const { t } = useTranslation();
  const [data, setData] = useState<T | undefined>(options?.initialValue);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [response, setResponse] = useState<AxiosResponse | undefined>();
  const dispatch = useDispatch();
  const optionHeaders = options?.headers;
  const responseType = options?.responseType ?? 'json';
  const history = useHistory();
  const location = useLocation();
  let locale = getActiveLocale(location.pathname);
  if (locale === BRITISH) locale = 'en';
  const accessToken = useSelector((state: Store) => state.auth?.accessToken);
  const refreshToken = useSelector((state: Store) => state.auth?.refreshToken);
  const isWithToken = options?.withToken;
  const optionBody = options?.body;
  const optionMethod = options?.method;
  const optionParams = options?.params;
  const load = useCallback(
    (loadOptions: {
      params?: Array<string>;
      initialValue?: T;
      method?: Methods;
      headers?: Record<string, string>;
      body?: string | FormData;
      cache?: RequestCache;
    }): Promise<T> => {
      setLoading(true);
      const params = loadOptions?.params ?? optionParams;
      const method = loadOptions?.method ?? optionMethod ?? 'get';
      const body = loadOptions?.body ?? optionBody;
      const headers: Record<string, string> =
        loadOptions?.headers ?? optionHeaders ?? {};
      if (isWithToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      let apiUrl = api;
      if (Array.isArray(params)) {
        params.forEach((param: string, index: number) => {
          apiUrl = apiUrl.replace(`{${index + 1}}`, param);
        });
      }
      return new Promise<T>((resolve, reject) => {
        axios({
          baseURL: process.env.REACT_APP_API_URL,
          url: apiUrl,
          method,
          responseType,
          headers: { ...headers, 'Accept-Language': locale },
          data: body,
        }).then(
          (res) => {
            setLoading(false);
            if ([200, 201, 202].indexOf(res.status) > -1) {
              setData(
                responseType === 'json' ? toCamelCase<T>(res.data) : res.data,
              );
              setResponse(res);
              resolve(
                responseType === 'json' ? toCamelCase<T>(res.data) : res.data,
              );
            }
          },
          (e) => {
            if (e.response?.status === 403 || e.response?.status === 401) {
              if (refreshToken && refreshToken.length > 0) {
                axios({
                  baseURL: process.env.REACT_APP_API_URL,
                  url: REFRESH_TOKEN_API,
                  method: 'POST',
                  data: `{"refresh": "${refreshToken}"}`,
                  headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': locale,
                  },
                }).then(
                  (result) => {
                    if (result.data.refresh && result.data.access) {
                      dispatch(
                        setToken(result.data.access, result.data.refresh),
                      );
                      axios({
                        baseURL: process.env.REACT_APP_API_URL,
                        url: apiUrl,
                        method,
                        headers: {
                          ...optionHeaders,
                          'Accept-Language': locale,
                          Authorization: `Bearer ${result.data.access}`,
                        },
                        data: body,
                      }).then((res) => {
                        setLoading(false);
                        if ([200, 201, 202].indexOf(res.status) > -1) {
                          setData(toCamelCase<T>(res.data));
                          setResponse(res);
                          resolve(toCamelCase<T>(res.data));
                        }
                      });
                    }
                  },
                  (refreshError) => {
                    if (refreshError.response.status === 401) {
                      dispatch(
                        logout(
                          t('Session expired, please login again.'),
                          location.pathname,
                        ),
                      );
                      history.push({
                        pathname: l(LOGIN_PAGE),
                        state: { prevPath: location.pathname },
                      });
                    }
                  },
                );
              } else if (api === TOKEN_API) {
                setError(e);
              } else {
                setError(e);
                history.push({
                  pathname: l(LOGIN_PAGE),
                  state: { prevPath: location.pathname },
                });
              }
            } else {
              setError(e);
              reject(e);
              setLoading(false);
            }
          },
        );
      });
    },
    [
      refreshToken,
      isWithToken,
      history,
      l,
      dispatch,
      api,
      accessToken,
      location.pathname,
      optionHeaders,
      optionParams,
      optionMethod,
      optionBody,
      t,
      locale,
      responseType,
    ],
  );

  return { load, data, loading: isLoading, error, response };
};

export default useLazyApi;
