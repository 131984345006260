import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BannerWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  position: relative;
  background-color: ${themeGet('color.1', '#ffffff')};

  .banner-content {
    bottom: 81px;
    top: -81px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1410px) {
    > div {
      width: 100%;
      min-height: calc(100vh - 150px) !important;
    }

    .banner-content {
      bottom: 150px;
      top: -150px;
    }
  }
  @media only screen and (max-width: 991px) {
    > div {
      width: 100%;
      min-height: calc(100vh - 35px) !important;
    }

    .banner-content {
      bottom: 35px;
      top: -35px;
    }
  }

  .glide,
  .glide__track,
  .glide__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  .glide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .glide__bullet {
    width: 7px;
    height: 7px;
    background-color: ${themeGet('color.1', '#ffffff')};
    transition: width 0.3s ease;
  }

  .glide__bullet--active {
    width: 25px;
    border-radius: 8px;
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  > div {
    position: relative;
    min-height: calc(100vh - 80px);
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${themeGet('color.2', '#F7F7F7')};
  border-radius: 3px 0 0 3px;
  min-height: 60px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  /* submit button style */
  button[type='submit'].ant-btn {
    min-width: 157px;
    height: 60px;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#008489')};
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      border-radius: 0 0 3px 3px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const RoomGuestWrapper = styled.div`
  width: 100%;

  strong {
    font-size: 15px;
    font-weight: 400;
    color: ${themeGet('text.0', '#2C2C2C')};
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 5px;
  }

  .quantity {
    height: 30px;

    input {
      font-size: 15px;
    }

    button.btn svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export default BannerWrapper;
