import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const PageWrapper = styled.div`
  //max-width: 1920px;
  padding: 15px 0;

  .w-full {
    width: 100% !important;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  > div {
    margin-bottom: 30px;
    border-radius: 3px;
    padding: 15px 0px;
    color: ${themeGet('text.0', '#2C2C2C')};

   /* @media only screen and (max-width: 991px) {
      margin-top: 45px;
      margin-bottom: 15px;
      padding: 45px 25px;
    }*/
    @media only screen and (max-width: 767px) {
      //padding: 0 25px;
      background-color: transparent;
    }
    // @media only screen and (max-width: 480px) {
    //   padding: 0 25px;
    // }

    h1 {
      font-size: 25px;
      font-weight: 700;
      padding-top: 10px;
      &:first-child {
        padding-top: 0;
      }
    }
    h2 {
      font-size: 23px;
      font-weight: 700;
      padding-top: 10px;
      &:first-child {
        padding-top: 0;
      }
    }

    h3:not(.about-title) {
      font-size: 1.125rem;
      font-weight: 600;
    }

    h4,
    h5,
    h6 {
      font-size: 15px;
      padding-top: 20px;
    }

    p:not(.about-p) {
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 27px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul:not(.about-ul),
    ol {
      padding: 0;
      margin-bottom: 27px;
      li {
        font-size: 15px;
        line-height: 30px;
      }
    }
  }

    @media only screen and (min-width: 768px) {
    .about-page {
      .content {
        margin-top: 50px;
        z-index: 1;
        
        .inner-content {
            border-radius: 1.5rem;
        }
      }
      
      .right-card {
          grid-template-columns: repeat(5, [col] 1fr ) ;
           grid-template-rows: repeat(1, [row] auto  );
           
           img {
             grid-column: col / span  3;
             grid-row: row ;
           }
           
           .content {
             grid-column: col 3 / span  3;
             grid-row: row ;
           }
      }
      
       .left-card {
          grid-template-columns: repeat(6, [col] 1fr ) ;
          grid-template-rows: repeat(1, [row] auto  );
           
           img {
             grid-column: col 4 / span  3;
             grid-row: row ;
           }
           
            .content {
                grid-column: col / span  4;
                grid-row: row ;
            }
      }
    }
`;

export default PageWrapper;
