/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
// eslint-disable-next-line
// @ts-ignore
import Glide from '@glidejs/glide';
import useTranslation from 'localizer';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { Col, Row } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import GlideWrapper, {
  GlideSlideWrapper,
  ButtonControlWrapper,
  BulletControlWrapper,
  BulletButton,
  DefaultBtn,
} from './GlideCarousel.style';
import ImageCard from '../../ImageCard/ImageCard';
import { DESTINATIONS_PAGE } from '../../../settings/constant';

type Props = {
  className?: string;
  children?: ReactElement;
  options?: Record<string, unknown>;
  controls?: boolean;
  bullets?: boolean;
  numberOfBullets?: number;
  prevButton?: string | ReactElement;
  nextButton?: string | ReactElement;
  carouselSelector?: string;
  data?: any;
};

const GlideCarousel = ({
  className,
  children,
  options,
  controls,
  prevButton,
  nextButton,
  bullets,
  numberOfBullets,
  carouselSelector,
  data,
}: Props): ReactElement => {
  const [glide, setGlide] = useState(null);
  const [isValidSlider, setIsValidSlider] = useState(true);
  const { width } = useWindowSize();
  const { t } = useTranslation();

  // Add all classs to an array.
  const addAllClasses = ['glide'];

  // className prop checking.
  if (className) {
    addAllClasses.push(className);
  }

  // number of bullets loop.
  const totalBullets = [];
  for (let i = 0; i < (numberOfBullets || 0); i += 1) {
    totalBullets.push(i);
  }

  // check if slider is needed or not
  const checkSliderIsValid = (
    sliderOptions: any,
    numberOfItems: number,
  ): boolean => {
    let isValid = true;

    if (sliderOptions && width) {
      if (width > 1440 || !sliderOptions.breakpoints) {
        return numberOfItems > sliderOptions.perView;
      }

      const breakpointsList = Object.entries(sliderOptions.breakpoints);
      breakpointsList.reduce((sizeIsFind: boolean, item: any) => {
        if (width <= Number(item[0]) && !sizeIsFind) {
          isValid = numberOfItems > item[1].perView;
          return true;
        }
        return sizeIsFind;
      }, false);
    }

    return isValid;
  };

  useEffect(() => {
    setIsValidSlider(checkSliderIsValid(options, data.length));
  }, [width, options, data.length]);

  // Load glide.
  useEffect(() => {
    if (glide && isValidSlider) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      glide.destroy();
    }

    if (isValidSlider) {
      const newGlide = new Glide(
        carouselSelector ? `#${carouselSelector}` : '#glide',
        {
          ...options,
        },
      );

      newGlide.mount();
      setGlide(newGlide);
    }
  }, [carouselSelector, options, data, isValidSlider]);

  const imageCardComponent = (location: any) => (
    <ImageCard
      link={`${DESTINATIONS_PAGE}/${location.slugName}`}
      imageSrc={
        location.mediaFiles[0]?.photoThumbnail
          ? location.mediaFiles[0]?.photoThumbnail
          : location.mediaFiles[0]?.media
      }
      title={location.name}
    />
  );

  return (
    <GlideWrapper
      className={addAllClasses.join(' ')}
      id={carouselSelector ?? 'glide'}
    >
      {isValidSlider ? (
        <>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {data?.map((location: any) => (
                <GlideSlideWrapper
                  className="glide__slide"
                  key={location.slugName}
                >
                  {imageCardComponent(location)}
                </GlideSlideWrapper>
              ))}
            </ul>
          </div>

          {/** if controls prop true then show controls nav. */}
          {controls && (
            <ButtonControlWrapper
              className="glide__controls"
              data-glide-el="controls"
            >
              <DefaultBtn className="glide__prev--area" data-glide-dir="<">
                {prevButton ?? t('Prev')}
              </DefaultBtn>
              <DefaultBtn className="glide__next--area" data-glide-dir=">">
                {nextButton ?? t('Next')}
              </DefaultBtn>
            </ButtonControlWrapper>
          )}

          {/** if bullets prop true then show bullets nav. */}
          {bullets && (
            <BulletControlWrapper
              className="glide__bullets"
              data-glide-el="controls[nav]"
            >
              <>
                {totalBullets.map((index) => (
                  <BulletButton
                    key={index}
                    className="glide__bullet"
                    data-glide-dir={`=${index}`}
                  />
                ))}
              </>
            </BulletControlWrapper>
          )}
        </>
      ) : (
        <Row gutter={10}>
          {data?.map((location: any) => (
            <Col xl={4} lg={6} md={8} sm={12} xs={24} key={location.slugName}>
              {imageCardComponent(location)}
            </Col>
          ))}
        </Row>
      )}
    </GlideWrapper>
  );
};

GlideCarousel.defaultProps = {
  className: undefined,
  children: undefined,
  options: undefined,
  controls: undefined,
  bullets: undefined,
  numberOfBullets: undefined,
  prevButton: undefined,
  nextButton: undefined,
  carouselSelector: undefined,
  data: undefined,
};

// GlideCarousel default props.
GlideCarousel.defaultProps = {
  controls: true,
};

export default GlideCarousel;
