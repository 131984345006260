import 'moment/locale/de-at';
import moment from 'moment';

export { default } from './useTranslation';

export const AUSTRIAN = 'de-at';
export const BRITISH = 'en';

export const locales = [AUSTRIAN, BRITISH];

export type ValuesOf<T extends string[]> = T[number];

export const getActiveLocale = (
  currentPath: string,
): ValuesOf<typeof locales> => {
  let locale = AUSTRIAN;
  locales.forEach((loc) => {
    if (currentPath.startsWith(`/${loc}`)) {
      locale = loc;
    }
  });

  moment.locale(locale);
  return locale;
};
