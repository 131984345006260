/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { ReactElement } from 'react';
import { Store } from 'types';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { setShowFRCHPopup } from 'redux/actions/layout';
import moment from 'moment';
import useTranslation from 'localizer';

const FRCHModal = (): ReactElement => {
  const loggedIn = useSelector(
    (state: Store): string | undefined => state.auth?.refreshToken,
  );
  const userInfo = useSelector((state: Store) => state.session?.user);

  const currentDate = moment();
  const registerDate = userInfo ? Date.parse(userInfo?.dateJoined) : '';
  const joinedDate = moment(registerDate);
  const dateDiff = currentDate.diff(joinedDate, 'days');
  const dispatch = useDispatch();

  const isShowFRCHPopup = useSelector(
    (state: Store): string | undefined => state.layout?.showFRCHPopup,
  );

  const { t } = useTranslation();

  if (
    userInfo?.companyInitial === 'FRCH' &&
    loggedIn &&
    dateDiff <= 21 &&
    isShowFRCHPopup === ''
  ) {
    dispatch(setShowFRCHPopup('show'));
  }

  return isShowFRCHPopup === 'show' ? (
    <Modal
      visible
      onCancel={(): any => dispatch(setShowFRCHPopup('hide'))}
      footer={null}
      width="40%"
    >
      <div dangerouslySetInnerHTML={{ __html: t('FRCHModalText') ?? '' }} />
    </Modal>
  ) : (
    <></>
  );
};

export default FRCHModal;
