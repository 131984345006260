export type State = {
  searchVisibility?: boolean;
  cookiePrefVisibility?: boolean;
  showNewsletterPopup?: string;
  showFRCHPopup?: string;
};

export type Action = {
  searchVisibility?: boolean;
  cookiePrefVisibility?: boolean;
  showNewsletterPopup?: string;
  showFRCHPopup?: string;
  type: string;
};

// Action Types
export const CHANGE_SEARCH_VISIBILITY = 'CHANGE_SEARCH_VISIBILITY';

export const CHANGE_COOKIE_PREF_VISIBILITY = 'CHANGE_COOKIE_MODAL_VISIBILITY';

export const SHOW_NEWSLETTER_POPUP = 'SHOW_NEWSLETTER_POPUP';
export const SHOW_FRCH_POPUP = 'SHOW_FRCH_POPUP';
