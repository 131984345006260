import axios from 'axios';
import logger from './logger';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const isDebug = process.env.NODE_ENV === 'development';

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.response.use((x) => {
  if (isDebug) {
    const group = logger.formatMessage(x.config.method, x.config.url);
    logger.logging.groupCollapsed(...group);
    logger.logging.log('REQUEST_HEADERS', [x.config.headers]);
    logger.logging.log('INIT', x.config.method, x.status, x.statusText);
    logger.logging.log('RESULT', x.data);
    logger.logging.groupEnd(...group);
  }

  return x;
});

export default axiosInstance;
