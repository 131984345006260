export { default as useApi } from './useApi';
export { toCamelCase } from './useApi';
export { default as useLazyApi } from './useLazyApi';
export { default as axios } from './axios';

export const TOKEN_API = 'api/token/?format=json';
export const SIGNUP_API = 'api/user/register/';
export const SIGNUP_VERIFICATION =
  '/api/user/register-check-hash-key/?verification_code={1}';
export const SIGNUP_SEND_VERIFICATION = 'api/user/send-verification-email/';

export const SEARCH_API = 'api/search/';

export const USER_API = 'api/user/';
export const HOTELS_API = 'api/hotels/';
export const BOOKING_REQUEST_API = 'api/booking-requests/';
export const BOOKING_PACKAGE_API = 'api/special-packages/booking/';
export const BOOKING_VIPOFFER_API = 'api/vip-offers/booking/';
export const BOOKING_DETAILS_API = '/api/booking-requests/{1}/?format=json';
export const BOOKING_DETAILS_PDF_API =
  'api/booking-requests/get-approved-pdf/{1}/?format=json';
export const BOOKING_DETAILS_CANCEL = '/api/booking-requests/{1}/';
export const CANCEL_BOOKING = '/api/booking-request-cancellations/';
export const COMMUNICATION_MESSAGE = 'api/booking-requests/{1}/communications/';
export const FAVORITE_HOTELS_API = 'api/hotels/?favorites=true';
export const HOTEL_API = 'api/hotels/{1}/?format=json';
export const HOTEL_CALENDAR_API = 'api/hotels/{1}/calendar';
export const PAGES_API = 'api/pages/?format=json';
export const PAGE_API = 'api/pages/{1}/?format=json';
export const CONTENT_BLOCK_API = 'api/blocks/{1}/?format=json';
export const DESTINATIONS_API = 'api/destinations/';
export const DESTINATION_API = 'api/destinations/{1}/?format=json';
export const ATTRACTIONS_API = 'api/attractions/';
export const GOLF_COURSES_API = 'api/golf-courses/';
export const GOLF_COURSE_DETAIL_API = 'api/golf-courses/{1}/';
export const ATTRACTION_DETAIL_API = 'api/attractions/{1}/';
export const ATTRACTION_API = 'api/attractions/{1}/?format=json';
export const HOME_SLIDES = 'api/slider-media-files/?format=json';
export const WELCOME_MESSAGE_SLUG = 'welcome-message';
export const RECOMMENDATION_HOTELS = 'api/hotels/?recommended=true';

export const INSIGHTS_API = 'api/insights/';
export const INSIGHTS_DETAIL_API = 'api/insights/{1}/?format=json';

export const OFFERS_API = 'api/booking-offers/?format=json';
export const OFFER_API = 'api/booking-offers/{1}/?format=json';
export const UPDATE_OFFER_API = 'api/booking-offers/{1}/';

export const FILTER_BAR = 'api/filter-bar/?format=json';

export const LOCAL_BUSINESSES_API = 'api/local-businesses/?format=json';
export const LOCAL_BUSINESS_API = 'api/local-businesses/{1}/?format=json';
export const LOCAL_BUSINESS_REVIEW_API =
  'api/local-businesses/add-comment-rating/';
export const LOCAL_BUSINESS_COMMENTS_API = 'api/local-businesses/comments/{1}/';

export const CONTACTS_API = 'api/contacts/?format=json';
export const CONTACT_MESSAGE_API = 'api/contact-us/send-mail/';
export const CONTACTS_API_SEO = 'api/contacts-seo/?format=json';

export const BOX_CODES_API = 'api/box-codes/';
export const BOX_CODE_ASSIGN_API = 'api/user/box-codes/assign/';

export const EDIT_PROFILE_API = 'api/user/update/';
export const EDIT_PROFILE_PHOTO_API = 'api/user/change-photo/';
export const PROFILE_API = 'api/user/';

export const CHANGE_PASSWORD_API = 'api/user/change-password/';
export const FORGET_PASSWORD_API = 'api/user/forget-password/';
export const PASSWORD_KEY_VALIDATE_API = 'api/user/check-hash-key/{1}';
export const RESET_PASSWORD_API = 'api/user/reset-password/';
export const GET_CREDIT_CARD_API = 'api/user/get-credit-card/';

export const FAQs_API = 'api/guest-faqs/';

// google
export const GOOGLE_MAP_URL_API = 'api/config/google-feature/google-map-url';
export const GOOGLE_MAP_KEY_API =
  'api/config/google-feature/google-map-api-key';
export const GOOGLE_ANALYTIC_API = 'api/config/google-feature/google-analytics';

// Regions
export const REGIONS_API = 'api/regions/{1}/';

// Hotel partner
export const HOTEL_PARTNER_API = 'api/hotel-request-partner/';
export const USER_TUTORIAL_API = 'api/guest-tutorial/';
// Vouchers
export const BOXCODE_VOUCHER_API = 'api/guest-boxcode-voucher/';
// Career
export const CAREER_API = 'api/career/';
export const CAREER_JOBS = 'api/job-position/';

// Newsletter
export const NEWSLETTER_REGISTER = '/api/newsletter-register/';
export const NEWSLETTER_VERIFICATION =
  '/api/newsletter-verification/?verification_code={1}';
// Code Request
export const CODE_REQUEST_API = 'api/boxcode-request/';
