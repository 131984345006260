import { useCallback, useEffect, useState } from 'react';

const useWindowSize = (): {
  width: number | undefined;
  height: number | undefined;
} => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient],
  );

  const [size, setSize] = useState(getSize);

  useEffect(() => {
    const onHandleResize = (): void => {
      setSize(getSize);
    };
    window.addEventListener('resize', onHandleResize);
    return (): void => window.removeEventListener('resize', onHandleResize);
  }, [getSize]);

  return size;
};

export default useWindowSize;
