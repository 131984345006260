import { useState, useReducer, useEffect } from 'react';

async function SuperFetch<T>(
  url: string,
  method = 'GET',
  headers: HeadersInit = {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  body?: BodyInit,
): Promise<T> {
  let options: {
    method: string;
    headers: HeadersInit;
    body: BodyInit | undefined;
  } = {
    method,
    headers,
    body: undefined,
  };
  if (method === 'POST' || method === 'PUT') options = { ...options, body };

  // authentication
  // we will had custom headers here.

  return fetch(url, options)
    .then((res) => {
      return Promise.resolve(res.json());
    })
    .catch((error) => Promise.reject(error));
}

type State<T> = {
  loading: boolean;
  error: boolean;
  data: Array<T>;
  total: Array<T>;
  limit: number;
};

function dataFetchReducer<T>(
  state: State<T>,
  action: { type: string; payload?: State<T>['data'] },
): State<T> {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: action.payload?.slice(0, state.limit) ?? [],
        total: action.payload ?? [],
        loading: false,
        error: false,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case 'LOAD_MORE':
      return {
        ...state,
        data: [
          ...state.data,
          ...state.total.slice(
            state.data.length,
            state.data.length + state.limit,
          ),
        ],
        loading: false,
        error: false,
      };
    default:
      throw new Error();
  }
}

const useDataApi = <T>(
  initialUrl: string,
  limit = 10,
  initialData = [],
): State<T> & { doFetch: (url: string) => void; loadMoreData: () => void } => {
  const [url, setUrl] = useState(initialUrl);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: initialData,
    total: initialData,
    limit,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const result = await SuperFetch<T>(url);
        if (!didCancel) {
          // eslint-disable-next-line
          // @ts-ignore
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      }
    };

    fetchData();

    return (): void => {
      didCancel = true;
    };
  }, [url]);
  const loadMoreData = (): void => {
    dispatch({ type: 'LOAD_MORE' });
  };
  const doFetch = (u: string): void => {
    setUrl(u);
  };

  // eslint-disable-next-line
  // @ts-ignore
  return { ...state, doFetch, loadMoreData };
};

export default useDataApi;
