import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONTACTS_API, useApi } from 'api';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useLink } from 'utils';
import { Contact } from 'types';

type Props = {
  className?: string;
  linkTo: string;
  linkedInURL: string;
  contactEmail: string;
  title?: string;
  src: string;
  imgStyles?: any;
  imgClassName?: string;
};

const FooterLogo = ({
  className,
  linkTo,
  linkedInURL,
  contactEmail,
  title,
  src,
  imgStyles,
  imgClassName,
}: Props): ReactElement => {
  const { l } = useLink();

  const { data: contactData, loading: isLoading } = useApi<Array<Contact>>(
    CONTACTS_API,
  );

  let address;
  if (!isLoading) {
    address = contactData?.filter(
      (item) =>
        item.key.toLowerCase().includes('address') ||
        item.key.toLowerCase().includes('adresse'),
    )[0]?.value;
  }

  return (
    <div className={className}>
      <>
        <NavLink to={l(linkTo)}>
          {src && (
            <img
              style={imgStyles}
              src={src}
              alt="Green Habitat."
              className={imgClassName}
            />
          )}
          {title && <h3>{title}</h3>}
        </NavLink>
        <div className="text-dark-theme footer-address">{address}</div>
        <div className="flex items-start gap-3 mt-4 mb-1">
          <a href={linkedInURL} target="_blank">
            <FontAwesomeIcon
              icon={['fab', 'linkedin-in']}
              className="footer-social-media"
            />
          </a>
          <a href={contactEmail}>
            <FontAwesomeIcon
              icon={['fas', 'envelope']}
              className="footer-social-media"
            />
          </a>
        </div>
      </>
    </div>
  );
};

FooterLogo.defaultProps = {
  className: undefined,
  title: undefined,
  imgStyles: undefined,
  imgClassName: undefined,
};

export default FooterLogo;
