import React, { ReactElement } from 'react';
import { useApi, USER_TUTORIAL_API } from 'api';
import { useSelector } from 'react-redux';
import { Store } from 'types';
import useTranslation from 'localizer';

const UserTutorial = (): ReactElement => {
  const accessToken = useSelector((state: Store) => state.auth?.accessToken);
  const { data } = useApi<{ url: string }>(USER_TUTORIAL_API, {
    initialValue: { url: '' },
    withToken: (accessToken && accessToken.length > 0) || false,
  });
  const { t } = useTranslation();

  return (
    <a href={data && data.url} target="_blank" rel="noreferrer">
      {t('User Tutorial')}
    </a>
  );
};

export default UserTutorial;
