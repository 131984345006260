/* eslint-disable no-console */
const bindToConsole = (consoleMethod: any, polyfill: any) => {
  return consoleMethod ? consoleMethod.bind(console) : polyfill;
};

const logging = (() => {
  let prefix = '';

  const consoleLog = (...args: any) => {
    console.log(prefix, ...args);
  };

  const consoleError = (...args: any) => {
    console.error(prefix, ...args);
  };

  const consoleGroup = (...args: any) => {
    consoleLog(...args);
    prefix += '> ';
  };

  const consoleGroupEnd = () => {
    prefix = prefix.slice(0, -2);
  };

  return {
    log: consoleLog,
    error: consoleError,
    group: bindToConsole(console.group, consoleGroup),
    groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
    groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd),
  };
})();

const formatMessage = (operationType: any, operation: any) => {
  const headerCss = [
    'color: gray; font-weight: lighter', // title
    `color: ${operationType === 'query' ? '#03A9F4' : 'red'};`, // operationType
    'color: inherit;', // operationName
  ];
  const parts = [
    '%c axios',
    `%c${operationType.toUpperCase()}`,
    `%c${operation}`,
  ];
  return [parts.join(' '), ...headerCss];
};

export default {
  logging,
  formatMessage,
};
