import { useEffect, useState } from 'react';
import { camelCase } from 'lodash';
import useTranslation, { getActiveLocale, BRITISH } from 'localizer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Store } from 'types';
import { setToken, logout } from 'redux/actions/auth';
import { LOGIN_PAGE } from 'settings/constant';
import { useLink } from 'utils';
import axios, { AxiosResponse, AxiosError } from 'axios';

const REFRESH_TOKEN_API = 'api/token/refresh/';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export const toCamelCase = <T>(obj: unknown): T => {
  // @ts-ignore
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    // @ts-ignore
    return obj.map((each) =>
      typeof each === 'object' ? toCamelCase(each) : each,
    );
  }
  const result = {};
  // @ts-ignore
  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    result[camelCase(key)] =
      // @ts-ignore
      typeof obj[key] === 'object' ? toCamelCase(obj[key]) : obj[key];
  });
  // @ts-ignore
  return result;
};
/* eslint-enable @typescript-eslint/ban-ts-comment */

const useApi = <T>(
  api: string,
  options?: {
    params?: Array<string>;
    initialValue?: T;
    method?: 'get' | 'post';
    headers?: Record<string, string>;
    body?: string;
    withToken?: boolean;
  },
): {
  data: T | undefined;
  loading: boolean;
  error: AxiosError | undefined;
  response: AxiosResponse | undefined;
  reload: () => void;
} => {
  const { l } = useLink();
  const {
    params,
    initialValue,
    method,
    headers: requestHeaders,
    body,
    withToken,
  } = options ?? {};
  const history = useHistory();
  const location = useLocation();
  const [headers, setHeaders] = useState<Record<string, string>>(
    requestHeaders ?? {},
  );
  const [key, setKey] = useState(0);
  const dispatch = useDispatch();
  const accessToken = useSelector((state: Store) => state.auth?.accessToken);
  const refreshToken = useSelector((state: Store) => state.auth?.refreshToken);
  let locale = getActiveLocale(location.pathname);
  if (locale === BRITISH) locale = 'en';
  let apiUrl = api;
  if (Array.isArray(params)) {
    params.forEach((param: string, index: number) => {
      apiUrl = apiUrl.replace(`{${index + 1}}`, param);
    });
  }
  const { t } = useTranslation();
  const [data, setData] = useState<T | undefined>(initialValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | undefined>();
  const [response, setResponse] = useState<AxiosResponse | undefined>();
  const reload = (): void => {
    setKey((k) => k + 1);
  };
  useEffect(() => {
    if (withToken) {
      const newHeaders: Record<string, string> = { ...requestHeaders };
      newHeaders.Authorization = `Bearer ${accessToken}`;
      setHeaders(newHeaders);
    }
  }, [withToken, requestHeaders, accessToken]);
  useEffect(() => {
    setLoading(true);
    if (withToken && !headers.Authorization) return;
    axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: apiUrl,
      method,
      headers: { ...headers, 'Accept-Language': locale },
      data: body,
    }).then(
      (res) => {
        if ([200, 201, 202].indexOf(res.status) > -1) {
          setLoading(false);
          setData(toCamelCase<T>(res.data));
          setResponse(res);
          setError(undefined);
        } else {
          setError({
            config: {},
            isAxiosError: false,
            toJSON: () => ({}),
            name: '',
            message: `${res.status} - ${res.statusText}`,
          });
        }
      },
      (e) => {
        if (e.response?.status === 403 || e.response?.status === 401) {
          if (refreshToken && refreshToken.length > 0) {
            axios({
              baseURL: process.env.REACT_APP_API_URL,
              url: REFRESH_TOKEN_API,
              method: 'POST',
              data: `{"refresh": "${refreshToken}"}`,
              headers: {
                'Content-Type': 'application/json',
                'Accept-Language': locale,
              },
            }).then(
              (result) => {
                if (result.data.refresh && result.data.access) {
                  dispatch(setToken(result.data.access, result.data.refresh));
                }
              },
              (refreshError) => {
                if (refreshError.response?.status === 401) {
                  dispatch(
                    logout(
                      t('Session expired, please login again.'),
                      location.pathname,
                    ),
                  );
                  history.push({
                    pathname: l(LOGIN_PAGE),
                    state: { prevPath: location.pathname },
                  });
                }
              },
            );
          } else {
            dispatch(
              logout(
                t('You need to login to see this page'),
                location.pathname,
              ),
            );
            history.push({
              pathname: l(LOGIN_PAGE),
              state: { prevPath: location.pathname },
            });
          }
        } else {
          setLoading(false);
          setError(e);
        }
      },
    );
  }, [
    apiUrl,
    method,
    body,
    headers,
    refreshToken,
    dispatch,
    history,
    withToken,
    l,
    location.pathname,
    locale,
    key,
    t,
  ]);
  return { data, loading, error, response, reload };
};

export default useApi;
