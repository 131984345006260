import React, { useState, useRef, ReactElement } from 'react';
import { Menu } from 'antd';
import useTranslation from 'localizer';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { NavLink, useHistory } from 'react-router-dom';
import {
  AGENT_ACCOUNT_SETTINGS_PAGE,
  AGENT_ACCOUNT_BOOKING_REQUESTS,
  FAQS_PAGE,
} from 'settings/constant';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/auth';
import { useLink } from 'utils';
import { Store } from 'types';
import UserTutorial from 'components/UserTutorial/UserTutorial';
import { setShowFRCHPopup, setShowNewsletterPopup } from 'redux/actions/layout';
import Flag from './Flag';

type Props = { userFirstName: string; isLoggedIn: boolean };
const ProfileMenu = ({ userFirstName, isLoggedIn }: Props): ReactElement => {
  const { l } = useLink();
  const user = useSelector((state: Store) => state.session.user);
  const loggedIn = useSelector((state: Store) => state.auth?.refreshToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDropDown, setState] = useState(false);
  const isLogin = true;

  const handleDropdown = (): void => {
    setState(!isDropDown);
  };
  const closeDropdown = (): void => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout(): void {
    dispatch(logout());
    dispatch(setShowNewsletterPopup(''));
    dispatch(setShowFRCHPopup(''));
    history.push(l('/'));
  }

  const { t } = useTranslation();

  const getMinimizedName = (): string => {
    if (!user || !user.firstName || !user.lastName) return 'NA';
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  };

  const boldGuestName = (): ReactElement => {
    return (
      <div
        role="presentation"
        className="dropdown-handler flex flex-nowrap pt-2 cursor-pointer"
        onClick={handleDropdown}
      >
        Hi,&nbsp;<span className="font-bold inline-block">{userFirstName}</span>
      </div>
    );
  };

  let userPhoto = user?.photo;
  if (
    user?.photo &&
    process.env.REACT_APP_API_URL &&
    !user?.photo.includes(process.env.REACT_APP_API_URL)
  ) {
    userPhoto = process.env.REACT_APP_API_URL + user?.photo.substring(1);
  }

  return (
    <div className="flex flex-nowrap">
      <Flag isLogin={isLogin} />
      {isLoggedIn ? boldGuestName() : ''}
      <div className="avatar-dropdown profile-avatar" ref={dropdownRef}>
        <div
          role="presentation"
          className="dropdown-handler"
          onClick={handleDropdown}
        >
          {user?.photo && <img src={userPhoto} alt="user-profile" />}
          {!user?.photo && (
            <div className="flex items-center justify-center w-full h-full tracking-widest text-white bg-gray-700">
              {getMinimizedName()}
            </div>
          )}
        </div>

        <Menu className={`dropdown-menu ${isDropDown ? 'active' : 'hide'}`}>
          <Menu.Item onClick={closeDropdown} key="0">
            <NavLink to={l(`${AGENT_ACCOUNT_BOOKING_REQUESTS}`)}>
              {t('My Booking Requests')}
            </NavLink>
          </Menu.Item>
          <Menu.Item onClick={closeDropdown} key="3">
            <NavLink to={l(AGENT_ACCOUNT_SETTINGS_PAGE)}>
              {t('Account Settings')}
            </NavLink>
          </Menu.Item>
          {loggedIn && (
            <Menu.Item onClick={closeDropdown} key="4">
              <UserTutorial />
            </Menu.Item>
          )}
          <Menu.Item onClick={closeDropdown} key="5">
            <NavLink to={l(FAQS_PAGE)}>{t('FAQs')}</NavLink>
          </Menu.Item>
          <Menu.Item key="6">
            <button type="button" onClick={handleLogout}>
              {t('Log Out')}
            </button>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default ProfileMenu;
