import React, { ComponentType, ReactElement, useEffect } from 'react';
import { Store } from 'types';
import { useLink } from 'utils';
import {
  Route,
  Redirect,
  Switch,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import urlUtils from 'utils/url';
import locales, { AUSTRIAN } from 'settings/locales';
import Home from 'container/Home/Home';
import Page from 'components/Page';
import {
  ATTRACTIONS_PAGE,
  DESTINATIONS_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOTELS_POSTS_PAGE,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  LOCAL_BUSINESSES_PAGE,
  GOLF_COURSE_PAGE,
  CONTACT_PAGE,
  SEARCH_ENTITY_PAGE,
  CHANGE_PASSWORD_PAGE,
  VERIFY_REGISTRATION_PAGE,
  AGENT_ACCOUNT_BOOKING_REQUESTS,
  FAQS_PAGE,
  INSIGHTS_PAGE,
  HOTEL_PARTNER,
  CAREER_PAGE,
  INFO_CENTER,
  CODE_REQUEST,
} from 'settings/constant';
import Layout from 'container/Layout/Layout';
import { Helmet } from 'react-helmet';
import useTranslation, { getActiveLocale } from 'localizer';

// import { clearRefreshToken } from './redux/actions/auth';

/**
 *
 * Public Routes
 *
 */
const Loading = (): ReactElement | null => null;

const routes = [
  {
    id: 2,
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignIn" */ './container/Auth/SignIn/SignIn'
        ),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    id: 3,
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/SignUp/SignUp'
        ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    id: 4,
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/ForgetPassword'
        ),
      loading: Loading,
      modules: ['ForgetPassword'],
    }),
  },
  {
    id: 5,
    path: `${SINGLE_POST_PAGE}/:slug/cancellation/:id`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    id: 6,
    path: `${SINGLE_POST_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    id: 7,
    path: HOTELS_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Hotels" */ './container/Hotels/Hotels'),
      loading: Loading,
      modules: ['Hotels'],
    }),
  },
  {
    id: 8,
    path: AGENT_PROFILE_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['AgentDetailsViewPage'],
    }),
  },
  {
    id: 10,
    path: `${DESTINATIONS_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Destination'),
      loading: Loading,
      modules: ['Destinations'],
    }),
  },
  {
    id: 11,
    path: DESTINATIONS_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Pricing" */ './container/Destination/List'
        ),
      loading: Loading,
      modules: ['Destinations'],
    }),
  },
  {
    id: 12,
    path: `${ATTRACTIONS_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Attraction'),
      loading: Loading,
      modules: ['Attractions'],
    }),
  },
  {
    id: 13,
    path: `${AGENT_ACCOUNT_BOOKING_REQUESTS}/:id`,
    component: Loadable({
      loader: () => import('./container/Agent/Booking/MyBookingDetail'),
      loading: Loading,
      modules: ['MyBookingDetail'],
    }),
  },
  {
    id: 14,
    path: AGENT_ACCOUNT_BOOKING_REQUESTS,
    component: Loadable({
      loader: () => import('./container/Agent/Booking/MyBookingList'),
      loading: Loading,
      modules: ['BookingDetailsViewPage'],
    }),
  },
  {
    id: 15,
    path: `${LOCAL_BUSINESSES_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/LocalBusiness'),
      loading: Loading,
      modules: ['LocalBusinesses'],
    }),
  },
  {
    id: 16,
    path: LOCAL_BUSINESSES_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Pricing" */ './container/LocalBusiness/List'
        ),
      loading: Loading,
      modules: ['LocalBusinesses'],
    }),
  },
  {
    id: 17,
    path: `${GOLF_COURSE_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/GolfCourse'),
      loading: Loading,
      modules: ['GolfCourse'],
    }),
  },
  {
    id: 18,
    path: GOLF_COURSE_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/GolfCourse/List'),
      loading: Loading,
      modules: ['GolfCourse'],
    }),
  },
  {
    id: 19,
    path: CONTACT_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Contact'),
      loading: Loading,
      modules: ['Contact'],
    }),
  },
  {
    id: 20,
    path: SEARCH_ENTITY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Hotels" */ './container/Search/Search'),
      loading: Loading,
      modules: ['Search'],
    }),
  },
  {
    id: 21,
    path: `${CHANGE_PASSWORD_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/ValidateNewPassword'
        ),
      loading: Loading,
      modules: ['ValidateNewPassword'],
    }),
  },
  {
    id: 22,
    path: `${VERIFY_REGISTRATION_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/SignUp/VerifyNewUser'
        ),
      loading: Loading,
      modules: ['VerifyUser'],
    }),
  },
  {
    id: 23,
    path: FAQS_PAGE,
    component: Loadable({
      loader: () => import('./container/FAQs'),
      loading: Loading,
      modules: ['FAQs'],
    }),
  },
  {
    id: 24,
    path: `${INSIGHTS_PAGE}/:slug/:blog`,
    component: Loadable({
      loader: () => import('./container/Insight/'),
      loading: Loading,
      modules: ['Insight'],
    }),
  },
  {
    id: 25,
    path: `${INSIGHTS_PAGE}/:slug`,
    component: Loadable({
      loader: () => import('./container/Insight/List'),
      loading: Loading,
      modules: ['Insight'],
    }),
  },
  {
    id: 26,
    path: INSIGHTS_PAGE,
    component: Loadable({
      loader: () => import('./container/Insight/List'),
      loading: Loading,
      modules: ['Insights'],
    }),
  },
  // *This part has been commented on due to the removal of the region detail page.*
  // {
  //   id: 27,
  //   path: REGIONS,
  //   component: Loadable({
  //     loader: () => import('./container/Regions'),
  //     loading: Loading,
  //     modules: ['Regions'],
  //   }),
  // },
  {
    id: 28,
    path: HOTEL_PARTNER,
    component: Loadable({
      loader: () => import('./container/HotelPartner'),
      loading: Loading,
      modules: ['HotelPartner'],
    }),
  },
  {
    id: 29,
    path: CAREER_PAGE,
    component: Loadable({
      loader: () => import('./container/Career'),
      loading: Loading,
      modules: ['Career'],
    }),
  },

  {
    id: 30,
    path: `${CAREER_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "karriere" */ './container/Career/JobPositionDetail'
        ),
      loading: Loading,
      // eslint-disable-next-line react/display-name
      modules: ['JobPositionDetail'],
    }),
  },
  {
    id: 31,
    path: INFO_CENTER,
    component: Loadable({
      loader: () => import('./container/FAQs'),
      loading: Loading,
      modules: ['FAQs'],
    }),
  },
  {
    id: 32,
    path: CODE_REQUEST,
    component: Loadable({
      loader: () => import('./container/CodeRequest'),
      loading: Loading,
      modules: ['CodeRequest'],
    }),
  },
];

const AgentAccountSettingsPage = Loadable<
  RouteComponentProps,
  RouteComponentProps
>({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

/**
 *
 * Not Found Route Component
 *
 */

const ProtectedRoute = ({
  component: Component,
  path,
}: {
  component: ComponentType;
  path: string;
}): ReactElement => {
  const loggedIn = useSelector(
    (state: Store): string | undefined => state.auth?.refreshToken,
  );

  return (
    <Route
      render={(): ReactElement =>
        loggedIn ? <Component /> : <Redirect to={LOGIN_PAGE} />
      }
      path={path}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */
const changeLanguageFunc = (i18n: any, locale: any): any => {
  if (locale && locale.length > 0 && i18n.language !== locale)
    i18n.changeLanguage(locale);
};
const Routes = (): ReactElement => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { pathname: currPath } = location;
  const locale = getActiveLocale(currPath);
  const { l } = useLink();

  useEffect(() => {
    changeLanguageFunc(i18n, locale);
  }, [i18n, locale]);

  const pathLocale = `/${
    locales.find((each) => currPath.indexOf(each) > -1) ?? ''
  }`;
  let name = currPath.split('/').slice(-1).pop();
  if (name == '') {
    const pathStrs = currPath.split('/');
    name = pathStrs[pathStrs.length - 2];
  }
  let page;
  page =
    name &&
    name !== 'en' &&
    name
      ?.split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  if (page && page.toLowerCase() === 'faqs') page = 'FAQs';

  return (
    <Layout>
      <Helmet>
        <title>
          {page
            ? `${page} | Green Habitat`
            : t('Green Habitat: Experience Moments of Happiness')}
        </title>
      </Helmet>
      <Switch>
        {routes.map(
          ({ id, path, component }): ReactElement => {
            const actualPath =
              locale === AUSTRIAN ? path : `${pathLocale}${path}`;
            if (path.includes('karriere')) {
              return (
                <Route key={id} path={actualPath} exact component={component} />
              );
            }
            return <Route key={id} path={actualPath} component={component} />;
          },
        )}
        <ProtectedRoute
          path={l(AGENT_ACCOUNT_SETTINGS_PAGE)}
          // eslint-disable-next-line
          component={AgentAccountSettingsPage as any}
        />
        <Route
          path="/:lang/"
          render={(
            props: RouteComponentProps<{ lang: string }>,
          ): ReactElement => {
            if (
              !locales.includes(props.match?.params.lang) &&
              locale !== AUSTRIAN
            ) {
              return <Redirect to={`/${locale}${props.location.pathname}`} />;
            }
            const pathname = `${props.location.pathname}`;
            if (urlUtils.isHomePage(pathname, locale)) {
              // eslint-disable-next-line
              return <Home />;
            }
            // eslint-disable-next-line
            return <Page />;
          }}
        />
        <Route path={pathLocale} component={Home} />
        <Route path="" component={Home} />
      </Switch>
    </Layout>
  );
};

export default Routes;
