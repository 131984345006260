import React from 'react';
import styled from 'styled-components';
import { base, themed } from '../Base';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  base,
  themed('Image'),
);

type Props = {
  src: string;
  alt: string;
};

const Image = ({ src, alt, ...props }: Props) => (
  // eslint-disable-next-line
  <ImageWrapper src={src} alt={alt} {...props} />
);

export default Image;
