const precedenceClassNames = (
  classNames?: string,
  precedentClassNames?: string,
): string => {
  if (!precedentClassNames || precedentClassNames.length < 1)
    return classNames ?? '';
  if (!classNames || classNames.length < 1) return precedentClassNames || '';
  const precedents = precedentClassNames.split(' ');
  const defaults = classNames.split(' ');
  const remained: Array<string> = [];
  precedents.forEach((each) => {
    if (each.startsWith('bg-')) {
      const idx = defaults.findIndex((e) => e.startsWith('bg-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('md:bg-')) {
      const idx = defaults.findIndex((e) => e.startsWith('md:bg-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('lg:mt-')) {
      const idx = defaults.findIndex((e) => e.startsWith('lg:mt-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('p-')) {
      const idx = defaults.findIndex((e) => e.startsWith('p-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('lg:p-')) {
      const idx = defaults.findIndex((e) => e.startsWith('lg:p-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('md:px-')) {
      const idx = defaults.findIndex((e) => e.startsWith('md:px-'));
      if (idx !== -1) defaults[idx] = each;
    } else if (each.startsWith('md:py-')) {
      const idx = defaults.findIndex((e) => e.startsWith('md:py-'));
      if (idx !== -1) defaults[idx] = each;
    } else {
      remained.push(each);
    }
  });

  return `${defaults.join(' ')} ${remained.join(' ')}`;
};

export default {
  precedenceClassNames,
};
