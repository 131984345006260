import { Action, SET_USER, State } from '../types/session';

const initialState: State = {
  user: undefined,
};

export default (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};
