import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorIcon = (): ReactElement => {
  return (
    <FontAwesomeIcon
      icon={['fal', 'times-circle']}
      className="text-redNotification anticon"
    />
  );
};

export default ErrorIcon;
