import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { base, themed } from '../Base';

const HeadingWrapper = styled('p')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed('Heading'),
);

type Props = {
  content?: string | ReactElement;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontFamily?: string | number | Array<string | number>;
  fontWeight?: string | number | Array<string | number>;
  textAlign?: string | number | Array<string | number>;
  lineHeight?: string | number | Array<string | number>;
  fontSize?: string | number | Array<string | number>;
  letterSpacing?: string | number | Array<string | number>;
  className?: string;
  color?: string;
};

const Heading = ({ content, ...props }: Props): ReactElement => (
  // eslint-disable-next-line
  <HeadingWrapper {...props}>{content}</HeadingWrapper>
);

Heading.defaultProps = {
  as: 'h2',
  fontWeight: 500,
  content: undefined,
  fontFamily: undefined,
  textAlign: undefined,
  lineHeight: undefined,
  letterSpacing: undefined,
  fontSize: undefined,
  color: undefined,
  className: undefined,
};

export default Heading;
