module.exports = {
  purge: [],
  theme: {
    extend: {
      inset: {
        95: '95px',
      },
      colors: {
        'gray-ultra': '#E6E6E6',
        'gray-50': '#f7f7f7',
        'grey-light': '#909090',
        'dark-theme': '#2C2C2C',
        gh: '#A77F34',
        'gh-hover': '#917032',
        secondary: '#3366A6',
        darkBlue: '#3366A6',
        footerGray: '#F8F8F8',
        bootstrapYellow: '#ffc107',
        'light-cream': '#fdfbf7',
        greenNotification: '#48bb78',
        redNotification: '#eb2f96',
        'bg-antd-gray': '#FAFAFA',
        'gh-mustard': '#F9F6ED',
        'gh-light': '#EADECA',
      },
      opacity: {
        90: '0.9',
      },
      borderRadius: {
        xl: '0.75rem',
        '2xl': '1rem',
      },
      spacing: {
        us: '0.125rem', // ultra small
        128: '32rem',
        192: '48rem',
        256: '64rem',
        288: '72rem',
        16: '4rem',
      },
      zIndex: {
        5: 5,
      },
      maxWidth: {
        us: '16rem',
        328: '328px',
      },
    },
  },
  variants: {
    textColor: ['responsive', 'hover', 'focus', 'active'],
    backgroundColor: ['responsive', 'hover', 'focus', 'active'],
    borderWidth: ['responsive', 'hover', 'focus', 'active'],
  },
  plugins: [],
  future: {
    removeDeprecatedGapUtilities: true,
  },
};
