import React, { ReactElement, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useTranslation, { getActiveLocale } from 'localizer';
import { Menu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'types';
import {
  HOTELS_POSTS_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  AGENT_ACCOUNT_BOOKING_REQUESTS,
  DESTINATIONS_PAGE,
  GOLF_COURSE_PAGE,
  ABOUT_PAGE,
  CONTACT_PAGE,
} from 'settings/constant';
import { logout } from 'redux/actions/auth';
import { useLink } from 'utils';
import UserTutorial from 'components/UserTutorial/UserTutorial';
import { setShowFRCHPopup, setShowNewsletterPopup } from 'redux/actions/layout';
import LocalePortal, { getFlag, getCountry } from './LocalePortal';

type Props = {
  className: string;
  onClose: () => void;
};

const MobileMenu = ({ className, onClose }: Props): ReactElement => {
  const dispatch = useDispatch();
  const { l } = useLink();
  const loggedIn = useSelector((state: Store) => state.auth?.refreshToken);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);

  return (
    <Menu className={className}>
      <Menu.Item onClick={onClose}>
        <NavLink to={l(HOTELS_POSTS_PAGE)}>{t('Hotels')}</NavLink>
      </Menu.Item>
      <Menu.Item onClick={onClose}>
        <NavLink to={l(DESTINATIONS_PAGE)}>{t('Destinations')}</NavLink>
      </Menu.Item>
      <Menu.Item onClick={onClose}>
        <NavLink to={l(GOLF_COURSE_PAGE)}>{t('Golf Course')}</NavLink>
      </Menu.Item>
      <Menu.Item onClick={onClose}>
        <NavLink to={l(ABOUT_PAGE)}>{t('About')}</NavLink>
      </Menu.Item>
      <Menu.Item onClick={onClose}>
        <NavLink to={l(CONTACT_PAGE)}>{t('Contact')}</NavLink>
      </Menu.Item>
      {loggedIn && (
        <>
          <Menu.Item onClick={onClose}>
            <NavLink to={l(`${AGENT_ACCOUNT_BOOKING_REQUESTS}`)}>
              {t('My Booking Requests')}
            </NavLink>
          </Menu.Item>

          <Menu.Item onClick={onClose}>
            <NavLink to={l(AGENT_ACCOUNT_SETTINGS_PAGE)}>
              {t('Account Settings')}
            </NavLink>
          </Menu.Item>

          <Menu.Item onClick={onClose}>
            <UserTutorial />
          </Menu.Item>

          <Menu.Item onClick={onClose}>
            <button
              type="button"
              onClick={(): void => {
                dispatch(logout());
                dispatch(setShowNewsletterPopup(''));
                dispatch(setShowFRCHPopup(''));
              }}
            >
              {t('Log Out')}
            </button>
          </Menu.Item>
        </>
      )}
      <Menu.Item onClick={(): void => setIsOpen(!isOpen)}>
        <a
          href="#lang"
          style={{
            width: 250,
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              width: 20,
              height: 20,
              marginRight: 16,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${getFlag(locale)})`,
            }}
          />
          {getCountry(locale, t)}
        </a>
      </Menu.Item>
      <LocalePortal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isMobile
        onClose={onClose}
      />
    </Menu>
  );
};

export default MobileMenu;
