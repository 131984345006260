import React, { ReactElement } from 'react';
import Footers from 'components/Footer/Footer';
import greenHabitatLogo from 'assets/images/logo-gh-green.svg';
import FooterMenu from './FooterMenu';
import FooterLogo from './FooterLogo';
import FooterIcon from './FooterIcon';

const Footer = (): ReactElement => {
  return (
    <Footers
      logo={
        <FooterLogo
          linkTo="/"
          linkedInURL="https://www.linkedin.com/company/greenhabitatgmbh/"
          contactEmail="mailto:Office@greenhabitat.at"
          className="xl:w-[60%] flex flex-col items-center md:items-start"
          imgStyles={{ width: 150 }}
          src={greenHabitatLogo}
        />
      }
      icon={<FooterIcon />}
      menu={<FooterMenu />}
    />
  );
};

export default Footer;
