import React, { ReactElement } from 'react';
import AliceCarouselReact from 'components/Carousel/AliceCarouselReact';
import { TopHotelsGrid } from './Grid';
import SearchArea from './Search/Search';
import LocationGrid from './Location/Location';

const Home = (): ReactElement => {
  return (
    <>
      <SearchArea />
      <TopHotelsGrid />
      <AliceCarouselReact />
      <LocationGrid />
    </>
  );
};

export default Home;
