import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useCallback } from 'react';
import { useLink } from 'utils';
import { Link } from 'react-router-dom';
import { INFO_CENTER } from 'settings/constant';
import { useSelector } from 'react-redux';
import { Store } from 'types';

const InfoCenterLink = (): ReactElement => {
  const loggedIn = useSelector(
    (state: Store): string | undefined => state.auth?.refreshToken,
  );

  const handleClick = useCallback(() => {
    const element = document.getElementById('infoId');
    const elementInfoText = document.getElementById('infoTextId');
    const elementInfoIcon = document.getElementById('infoIconId');

    if (element && elementInfoText && elementInfoIcon) {
      if (element.style.width === '' || element.style.width === '42px') {
        element.style.width = '90px';
        elementInfoText.style.display = 'inline-block';
      } else {
        element.style.width = '42px';
        elementInfoText.style.display = 'none';
      }
    }
  }, []);
  const { l } = useLink();
  if (loggedIn) {
    return <></>;
  }
  return (
    <>
      <div className="info-center-margin">
        <div className="info-center" id="infoId">
          <FontAwesomeIcon
            icon={['fal', 'info-circle']}
            style={{ color: 'white' }}
            className="info-center-icon cursor-pointer"
            id="infoIconId"
            onClick={() => {
              handleClick();
            }}
          />

          <Link className="link-text" to={l(INFO_CENTER)}>
            <div
              className="info-center-text"
              id="infoTextId"
              role="presentation"
              onClick={() => {
                handleClick();
              }}
            >
              <span
                className="cursor-pointer"
                role="presentation"
                id="infoCenterTextId"
              >
                Info Center
              </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InfoCenterLink;
