export interface State {
  accessToken?: string;
  refreshToken?: string;
  message?: string;
  pathname?: string;
  rememberMe?: boolean;
}

export interface Action {
  accessToken?: string;
  refreshToken?: string;
  message?: string;
  pathname?: string;
  rememberMe?: boolean;
  type: string;
}

// Action Types
export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_REFRESH_TOKEN = 'SET_TOKEN';
export const SET_REMEMBER_ME = 'REMEMBER_ME';
export const LOGOUT = 'LOGOUT';
