import React, { ReactElement, Suspense } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useTranslation from 'localizer';
import { useLink } from 'utils';
import { LOGIN_PAGE, REGISTRATION_PAGE } from 'settings/constant';
import useWindowSize from 'library/hooks/useWindowSize';
import Flag from './Flag';

const AuthMenu = (): ReactElement => {
  const { t } = useTranslation();
  const { l } = useLink();
  const { width } = useWindowSize();
  const location = useLocation();
  const isLogin = false;

  return (
    <div className="flex items-center justify-center text-sm leading-4">
      {width && width > 991 ? <Flag isLogin={isLogin} /> : ''}
      <NavLink
        to={{
          pathname: l(LOGIN_PAGE),
          state: { prevPath: location.pathname },
        }}
        className="mr-5 whitespace-no-wrap hover:text-gh font-semibold"
      >
        {t('Sign in')}
      </NavLink>
      <NavLink
        className="px-4 pt-3 pb-3 text-sm font-semibold text-white whitespace-no-wrap rounded-full bg-gh hover:opacity-90 hover:text-white"
        to={l(REGISTRATION_PAGE)}
      >
        {t('Sign up')}
      </NavLink>
    </div>
  );
};

export default function AuthMenuComponent(): ReactElement {
  return (
    <Suspense fallback="loading...">
      <AuthMenu />
    </Suspense>
  );
}
