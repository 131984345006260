import { IconProp } from '@fortawesome/fontawesome-svg-core';

const iconStriper = (icon: string): IconProp => {
  let res = icon?.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
  res = res?.replace(/'/g, '"');

  return res && JSON.parse(res);
};

export default iconStriper;
