import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Checkbox, Drawer, Modal, Button as LinkButton } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import useTranslation from 'localizer';
import cookieSvg from 'assets/images/cookie.svg';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'types';
import { setCookiePrefVisibility } from 'redux/actions/layout';

const Cookie = (): ReactElement => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isExtendedPrefChecked, setIsExtendedPrefChecked] = useState<boolean>(
    true,
  );
  const [isCookiePrefModalVisible, setIsCookiePrefModalVisible] = useState<
    boolean | undefined
  >(false);

  const isCookiePref = useSelector(
    (state: Store) => state.layout?.cookiePrefVisibility,
  );
  const [isCookieModalVisible, setIsCookieVisible] = useState<boolean>(false);

  const checkForStorage = useCallback((): void => {
    if (
      localStorage.getItem('extended-cookies') === null ||
      localStorage.getItem('cookies') !== 'accepted'
    ) {
      localStorage.removeItem('cookies');
      setCookieVisible();
    }
  }, []);

  const acceptCookies = (): void => {
    localStorage.setItem('cookies', 'accepted');
    localStorage.setItem('extended-cookies', 'true');
    window.location.reload();
    dispatch(setCookiePrefVisibility(false));
    setIsCookieVisible(false);
  };

  const refuseCookies = (): void => {
    localStorage.setItem('cookies', 'accepted');
    localStorage.setItem('extended-cookies', 'false');
    window.location.reload();
    dispatch(setCookiePrefVisibility(false));
    setIsCookieVisible(false);
  };

  const onCloseDrawer = (): void => {
    if (localStorage.getItem('cookies') === 'accepted') {
      setIsCookieVisible(false);
      dispatch(setCookiePrefVisibility(false));
    }
  };

  const setCookieVisible = (): void => {
    if (localStorage.getItem('cookies') !== 'accepted') {
      setIsCookieVisible(true);
    }
  };
  useEffect(() => {
    checkForStorage();
    dispatch(setCookiePrefVisibility(false));
  }, [checkForStorage, dispatch]);

  // handle cookiePrefModal
  useEffect(() => {
    setIsCookiePrefModalVisible(isCookiePref);
  }, [isCookiePref]);

  const cookiePreferences = (): void => {
    setIsCookiePrefModalVisible(true);
  };

  const closeCookiePrefModal = (): void => {
    setIsCookiePrefModalVisible(false);
    dispatch(setCookiePrefVisibility(false));
  };

  const applyCookiePref = (): void => {
    const checkboxValue: string = isExtendedPrefChecked ? 'true' : 'false';
    localStorage.setItem('extended-cookies', checkboxValue);
    localStorage.setItem('cookies', 'accepted');

    window.location.reload();
    dispatch(setCookiePrefVisibility(false));
    setIsCookieVisible(false);
    closeCookiePrefModal();
    onCloseDrawer();
  };

  const cookieContainer = (
    <div
      className={`cookie-container wrapper-fixed m-auto pt-4 ${
        width && width > 768 ? 'flex h-full' : 'w-full'
      }`}
    >
      <img
        src={cookieSvg}
        draggable="false"
        className={
          width && width > 768
            ? 'md:w-6/12 lg:w-5/12 xl:w-4/12 h-full object-contain mt-4'
            : 'w-full h-auto pt-4'
        }
        alt="cookie"
      />
      <div
        className={`my-4 lg:ml-24 lg:mr-16${
          width && width < 769 ? ' py-4 mx-6' : ''
        }`}
      >
        <h3 className="mb-5 text-2xl font-bold">{t('Cookies')}</h3>
        <p
          className="mb-5"
          dangerouslySetInnerHTML={{
            __html: t(
              'We use cookies on our website. Some of them are essential and always aktive, while others help us to improve this website and your experience. Decide for yourself which additional cookies should be set when you use our website.',
            ),
          }}
        />
        <div className="flex flex-col-reverse md:pt-0 xl:pt-10 xl:flex-row">
          <div className="flex justify-center md:pt-2 pt-4">
            <LinkButton
              onClick={cookiePreferences}
              type="link"
              className="reset-btn cookie-link p-0"
            >
              {t('Individual cookie settings')}
            </LinkButton>
          </div>
          <div className="flex flex-col justify-center  sm:flex-row xl:ml-auto">
            <button
              type="button"
              className="px-5 py-3 border shadow sm:mr-2 rounded-2xl"
              onClick={refuseCookies}
            >
              {t('Refuse')}
            </button>
            <button
              type="button"
              className="px-5 py-3 mt-2 sm:mt-0 accept-cookies-bnt rounded-2xl"
              onClick={acceptCookies}
            >
              {t('Accept all')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const cookiePreferencesComponent = (
    <>
      <div
        className={`cookie-container wrapper-fixed m-auto pt-4 ${
          width && width > 768 ? 'flex h-full' : 'w-full'
        }`}
      >
        <div
          className={`mt-8 sm:ml-16 lg:w-full sm:mr-16${
            width && width < 768 ? ' py-4 mx-6' : ''
          }`}
        >
          <h3 className="mb-5 text-2xl font-bold">{t('Cookie Preferences')}</h3>
          <div className="mb-5">
            <Checkbox checked className="cursor-not-allowed">
              {t('Functional Cookies (read-only)')}
            </Checkbox>
            <br />
            <small className="text-gray-600">
              {t(
                'Functional cookies are always active, otherwise the website will not be displayed correctly.',
              )}
            </small>
            <br />
            <Checkbox
              defaultChecked={
                localStorage.getItem('extended-cookies') !== null
                  ? localStorage.getItem('extended-cookies') === 'true'
                  : false
              }
              className="mt-4"
              onChange={(e: CheckboxChangeEvent): void =>
                setIsExtendedPrefChecked(e.target.checked)
              }
            >
              {t('Extended Cookies')}
            </Checkbox>
            <br />
            <small className="text-gray-600">
              {t('Contain cookies from Google Analytics and Google Maps.')}
            </small>
          </div>
          <i className="text-gray-700">
            {`${t(
              'You can change your cookie settings at any time by clicking on the “Cookie settings” link in the footer of the homepage.',
            )}`}
          </i>
          <div className="mt-4 text-right">
            <button
              type="button"
              className="px-5 py-3 mr-2 border shadow rounded-2xl"
              onClick={closeCookiePrefModal}
            >
              {t('Cancel')}
            </button>

            <button
              type="button"
              className="px-5 py-3 accept-cookies-bnt rounded-2xl"
              onClick={applyCookiePref}
            >
              {t('Save settings')}
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {width && width > 768 ? (
        <>
          <Drawer
            placement="bottom"
            closable={false}
            onClose={onCloseDrawer}
            visible={isCookieModalVisible}
            key="bottom"
            height={325}
            bodyStyle={{
              padding: 0,
            }}
            maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          >
            {cookieContainer}
          </Drawer>
          <Drawer
            placement="bottom"
            closable={false}
            onClose={closeCookiePrefModal}
            visible={isCookiePrefModalVisible}
            key="bottom"
            height={325}
            bodyStyle={{
              padding: 0,
            }}
            maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          >
            {cookiePreferencesComponent}
          </Drawer>
        </>
      ) : (
        <>
          <Modal
            visible={isCookieModalVisible}
            onCancel={onCloseDrawer}
            footer={null}
            closable={false}
            bodyStyle={{
              padding: 0,
            }}
          >
            {cookieContainer}
          </Modal>
          <Modal
            visible={isCookiePrefModalVisible}
            onCancel={closeCookiePrefModal}
            footer={null}
            closable={false}
            bodyStyle={{
              padding: 0,
            }}
          >
            {cookiePreferencesComponent}
          </Modal>
        </>
      )}
    </>
  );
};
export default Cookie;
