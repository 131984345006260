import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  rating: { name?: string; stars: string };
  type: string;
  ratingFieldName?: string;
};

const Rating = (props: Props): ReactElement => {
  const { rating, type, ratingFieldName } = props;
  let i;
  const ratingView = [];
  const floorValue = Math.floor(Number(rating?.stars?.substring(0, 1)));
  if (rating && rating.stars && rating.stars !== '0') {
    for (i = 0; i < 5; i += 1) {
      if (i < floorValue) {
        ratingView.push(
          <FontAwesomeIcon
            key={i}
            icon={['fas', 'star']}
            className="inline"
            size={type && type === 'map' ? 'xs' : 'sm'}
            style={{ color: 'orange' }}
          />,
        );
      }
    }
  }

  if (rating?.stars && rating.stars.toLowerCase().indexOf('s') > -1) {
    ratingView.push(
      <div
        key="s"
        className="inline py-px ml-2 text-xs font-bold bg-white border border-dashed rounded-full select-none"
        style={{
          color: '#ffa800',
          borderColor: '#ffa800',
          fontSize: 9,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        S
      </div>,
    );
  }

  return (
    <>
      {type && type === 'bulk' ? (
        <span className="text-base" title={rating?.name ?? ''}>
          {ratingView}
        </span>
      ) : (
        <>
          <span>{ratingFieldName}</span> {ratingView}
        </>
      )}
    </>
  );
};

Rating.defaultProps = {
  ratingFieldName: undefined,
};

export default Rating;
