import React, { ReactElement } from 'react';
import SectionTitleExtended from 'components/UI/Title/Title';
import SectionTitleWrapper from './SectionTitle.style';

type Props = {
  title: ReactElement;
  link?: ReactElement;
  className?: string;
  titleWrapperClassName?: string;
};

const SectionTitle = ({ ...props }: Props): ReactElement => {
  return (
    <SectionTitleWrapper>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SectionTitleExtended {...props} />
    </SectionTitleWrapper>
  );
};

SectionTitle.defaultProps = {
  link: undefined,
  className: undefined,
  titleWrapperClassName: undefined,
};

export default SectionTitle;
