import React, { ReactElement } from 'react';
import { useTheme } from 'themes';
import LoaderWrapper from './Loader.style';

const Loader = (): ReactElement => {
  const theme = useTheme();
  return (
    <LoaderWrapper className="loader">
      <svg enableBackground="new 0 0 0 0">
        <circle fill={theme.colors.gh} stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={theme.colors.gh} stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={theme.colors.gh} stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </LoaderWrapper>
  );
};

export default Loader;
