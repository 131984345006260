import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HOTELS_POSTS_PAGE,
  DESTINATIONS_PAGE,
  GOLF_COURSE_PAGE,
  CONTACT_PAGE,
  ABOUT_PAGE,
} from 'settings/constant';
import useTranslation from 'localizer';
import { useLink } from 'utils';

const MainMenu = (): ReactElement => {
  const { t } = useTranslation();
  const { l } = useLink();

  return (
    <Menu>
      <Menu.Item>
        <NavLink to={l(HOTELS_POSTS_PAGE)}>{t('Hotels')}</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={l(DESTINATIONS_PAGE)}>{t('Destinations')}</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={l(GOLF_COURSE_PAGE)}>{t('Golf Courses')}</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={l(ABOUT_PAGE)}>{t('About')}</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={l(CONTACT_PAGE)}>{t('Contact')}</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
