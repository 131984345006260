import {
  Action,
  CHANGE_COOKIE_PREF_VISIBILITY,
  CHANGE_SEARCH_VISIBILITY,
  SHOW_FRCH_POPUP,
  SHOW_NEWSLETTER_POPUP,
} from '../types/layout';

export const setSearchVisibility = (searchVisibility: boolean): Action => ({
  type: CHANGE_SEARCH_VISIBILITY,
  searchVisibility,
});

export const setCookiePrefVisibility = (
  cookiePrefVisibility: boolean,
): Action => ({
  type: CHANGE_COOKIE_PREF_VISIBILITY,
  cookiePrefVisibility,
});

export const setShowNewsletterPopup = (
  showNewsletterPopup: string,
): Action => ({
  type: SHOW_NEWSLETTER_POPUP,
  showNewsletterPopup,
});
export const setShowFRCHPopup = (showFRCHPopup: string): Action => ({
  type: SHOW_FRCH_POPUP,
  showFRCHPopup,
});

export default null;
