import React, { memo, ReactElement, useState } from 'react';
import Carousel from 'components/UI/GlideCarousel/Carousel';
import ReactPlayer from 'react-player';
import { useApi, HOME_SLIDES, WELCOME_MESSAGE_SLUG, PAGE_API } from 'api';
import type { Media, WelcomeMessage } from 'types';
import Image from 'components/Image';
import useTranslation from 'localizer';
import { TFunction } from 'i18next';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import BannerWrapper from './Search.style';

const VideoSlide = ({
  url,
  isSelected,
  setAutoPlay,
}: {
  url: string;
  isSelected?: boolean;
  setAutoPlay: (autoPlay: boolean) => void;
}): ReactElement => (
  <div className="player-wrapper ">
    <ReactPlayer
      width="100%"
      height="100%"
      className="react-player"
      url={url}
      playing={isSelected}
      onStart={(): void => setAutoPlay(false)}
      onBuffer={(): void => setAutoPlay(false)}
      onPlay={(): void => setAutoPlay(false)}
      onPause={(): void => setAutoPlay(true)}
      onEnded={(): void => setAutoPlay(true)}
    />
    <style jsx global>{`
      .player-wrapper {
        position: relative;
        padding-top: 56.25%;
      }
      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    `}</style>
  </div>
);

VideoSlide.defaultProps = { isSelected: false };

const MemoisedPlayer = memo(VideoSlide);

const ImageComponent = ({
  url,
  idx,
  t,
}: {
  url: string;
  idx: number;
  t: TFunction;
}): ReactElement => {
  return <Image key={url} src={url} alt={t('Welcome To Green Habitat')} />;
};

const isImage = (url: string): boolean =>
  url.match(/\.(jpeg|jpg|gif|png)$/) != null;

const SearchArea = (): ReactElement => {
  const { t } = useTranslation();

  const [autoPlay, setAutoPlay] = useState(true);
  const { data } = useApi<Media>(HOME_SLIDES);
  const { data: welcomeData } = useApi<WelcomeMessage>(PAGE_API, {
    params: [WELCOME_MESSAGE_SLUG],
  });

  return (
    <>
      <Helmet>
        {welcomeData?.metaDescription && (
          <meta name="description" content={welcomeData?.metaDescription} />
        )}
        {welcomeData?.metaRobot && (
          <meta name="robots" content={welcomeData?.metaRobot} />
        )}
        {welcomeData?.metaKeyword && (
          <meta name="keywords" content={welcomeData?.metaKeyword} />
        )}
        {welcomeData?.richSnippets &&
        welcomeData?.richSnippets?.includes('application/ld+json') ? (
          <script type="application/ld+json">{`
        {
            ${welcomeData?.richSnippets?.slice(
              welcomeData?.richSnippets?.indexOf('{') + 1,
              welcomeData?.richSnippets?.lastIndexOf('}'),
            )}
        }
    `}</script>
        ) : (
          welcomeData?.richSnippets && parse(welcomeData?.richSnippets)
        )}
      </Helmet>
      <BannerWrapper>
        {Array.isArray(data) && (
          <Carousel
            showArrows={false}
            stopOnHover
            interval={5000}
            className="home-slider"
            autoPlay={autoPlay}
          >
            {data.map((each, idx) =>
              isImage(each.media) ? (
                <ImageComponent
                  key={each.media}
                  url={each.media}
                  idx={idx}
                  t={t}
                />
              ) : (
                <MemoisedPlayer
                  key={each.media}
                  url={each.media}
                  setAutoPlay={setAutoPlay}
                />
              ),
            )}
          </Carousel>
        )}

        {welcomeData && (
          <div className="wrapper-horizontal relative">
            <div className="absolute ml-6 mt-20 top-0 left-0 flex flex-row-reverse fixed-buttons sm:w-1/2 lg:w-2/5 w-4/5 p-8 bg-white rounded-md shadow-md opacity-90 landing-header-img">
              <div
                className="text-xl font-normal text-left"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: welcomeData ? welcomeData.content : t('welcome'),
                }}
              />
            </div>
          </div>
        )}
        <style jsx global>
          {`
            .home-slider .slider-wrapper::after {
              display: block;
              content: '';
              width: 100%;
              height: 60%;
              position: absolute;
              bottom: 0;
              pointer-events: none;
              z-index: 0;
            }
            .search-items {
              max-width: 1110px;
              min-height: 140px;
            }
            .slider-wrapper,
            .slider-wrapper > ul {
              height: 100%;
            }

            p:last-child {
              //margin-top: 50px;
            }

            p:last-child a {
              font-size: 15px;
              color: #ffffff;
              background: #039536;
              border-radius: 30px;
              padding: 8px 20px;
              font-weight: 600;
            }
            p:last-child a:hover {
              opacity: 0.8;
            }
          `}
        </style>
      </BannerWrapper>
    </>
  );
};

export default SearchArea;
