import {
  LOGIN_PAGE,
  CHANGE_PASSWORD_PAGE,
  FORGET_PASSWORD_PAGE,
  REGISTRATION_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  VERIFY_REGISTRATION_PAGE,
} from 'settings/constant';

// it should receive the pathname not the url
const isHomePage = (pathname: string, locale: string): boolean => {
  return (
    pathname === '/' || pathname === `/${locale}` || pathname === `/${locale}/`
  );
};

// this utility can be used to determine if a page should have a footer and header or not,
// it returns true for full pages like sign in and sign up
const isCompletePage = (pathname: string, locale: string): boolean => {
  const array = [
    LOGIN_PAGE,
    CHANGE_PASSWORD_PAGE,
    FORGET_PASSWORD_PAGE,
    REGISTRATION_PAGE,
    VERIFY_REGISTRATION_PAGE,
  ];

  for (let i = 0; i < array.length; i += 1) {
    if (
      (pathname.includes(CHANGE_PASSWORD_PAGE) &&
        !pathname.includes(AGENT_ACCOUNT_SETTINGS_PAGE)) ||
      pathname.includes(VERIFY_REGISTRATION_PAGE)
    ) {
      return true;
    }
    if (
      pathname === array[i] ||
      pathname === `${locale}${array[i]}` ||
      pathname === `/${locale}${array[i]}`
    )
      return true;
  }

  return false;
};

export default {
  isHomePage,
  isCompletePage,
};
