import React, { memo, ReactElement } from 'react';
import Box from 'components/UI/Box/Box';
import css from 'csstype';
import { PostPlaceholder } from 'components/UI/ContentLoader/ContentLoader';
import type { Hotel } from 'types';
import Card from 'components/Card';
import Rating from 'components/UI/Rating/Rating';
import Favorite from 'components/UI/Favorite/Favorite';
import { SINGLE_POST_PAGE } from 'settings/constant';
import LoadMore from './LoadMore';
import useWindowSize from '../../library/hooks/useWindowSize';

type SectionGridProps = {
  data: Array<Hotel>;
  totalItem: number;
  paginationComponent?: ReactElement;
  handleLoadMore?: () => void;
  loadMoreComponent?: ReactElement;
  loading?: boolean;
  limit?: number;
  buttonText?: string;
  rowStyle?: css.Properties;
  columnStyle?: css.Properties;
  loadMoreStyle?: css.Properties;
  reload: () => void;
  hasFavoriate?: boolean;
  hasMap?: boolean;
  truncate?: boolean;
};

const SectionGrid = ({
  data = [],
  totalItem,
  limit,
  paginationComponent,
  handleLoadMore,
  loadMoreComponent,
  loading,
  buttonText,
  columnStyle,
  loadMoreStyle,
  reload,
  hasFavoriate,
  hasMap,
  truncate,
}: SectionGridProps): ReactElement => {
  const { width } = useWindowSize();

  const n = limit ? Number(limit) : 1;
  const limits = Array.from(Array(n), (x, index) => 1 + index);

  const isShowButton = data.length < totalItem;

  return (
    <div
      className={`${
        hasMap
          ? ' grid gap-4 grid-cols-1 md:grid-cols-2'
          : 'grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
      }`}
    >
      {data && data.length
        ? data.map((item) => (
            <Card
              key={item.slugName || item.slug}
              link={`${SINGLE_POST_PAGE}/${item.slugName}`}
              title={item.name}
              medias={item.mediaFiles}
              truncate={truncate}
              favorite={
                hasFavoriate ? (
                  <Favorite
                    slugName={item.slugName}
                    reload={reload}
                    isFavorite={item.favorite}
                  />
                ) : undefined
              }
              subtitle={`${item.address} • ${item.regions
                .map((region) => region.name)
                .join(', ')}`}
              categories={item.categories}
              hasSpecialOffer={item.hasSpecialOffer}
              rating={<Rating rating={item.rating} type="bulk" />}
            />
          ))
        : null}

      {loading &&
        limits.map((i) => (
          <Box
            key={i}
            // eslint-disable-next-line
            {...columnStyle}
            className={`${width && width < 640 ? 'w-full' : ''} ${
              hasMap
                ? 'grid gap-4 lg:grid-cols-2'
                : 'grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
            }`}
          >
            <PostPlaceholder />
          </Box>
        ))}

      {handleLoadMore && isShowButton && (
        <LoadMore
          handleLoadMore={handleLoadMore}
          loading={loading}
          buttonText={buttonText}
          loadMoreComponent={loadMoreComponent}
          loadMoreStyle={loadMoreStyle}
        />
      )}
      {paginationComponent && (
        <Box className="pagination_wrapper">{paginationComponent}</Box>
      )}
    </div>
  );
};

SectionGrid.defaultProps = {
  hasFavoriate: true,
  paginationComponent: undefined,
  handleLoadMore: undefined,
  loadMoreComponent: undefined,
  loading: undefined,
  limit: undefined,
  buttonText: undefined,
  truncate: true,
  rowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    mr: ['-10px', '-10px', '-10px', '-10px', '-10px', '-15px'],
    ml: ['-10px', '-10px', '-10px', '-10px', '-10px', '-15px'],
  },
  columnStyle: {
    pr: ['10px', '10px', '10px', '10px', '10px', '15px'],
    pl: ['10px', '10px', '10px', '10px', '10px', '15px'],
  },
  loadMoreStyle: {
    flexBox: true,
    justifyContent: 'center',
    mt: '1rem',
  },
  hasMap: false,
};

export default memo(SectionGrid);
