import React, { ReactElement, useState } from 'react';
import { compose, withProps } from 'recompose';
import { Input } from 'antd';
import useTranslation from 'localizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchInput = compose<{ getInputValue: any }, { getInputValue: any }>(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
  }),
)((props) => {
  const { getInputValue } = props;
  const { t } = useTranslation();
  const [locationInput, setLocationInput] = useState<{
    searchedLocation?: string;
    searchedPlaceAPIData?: Array<string>;
  }>({
    searchedLocation: '',
    searchedPlaceAPIData: [],
  });
  const handleOnChange = (event: any): void => {
    if (event.which === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
    setLocationInput({
      searchedLocation: event.target.value,
    });
  };

  const handleSearch = (): void => {
    getInputValue(locationInput);
  };

  return (
    <>
      <div className="map_autocomplete">
        <Input
          type="text"
          defaultValue=""
          value={locationInput.searchedLocation ?? ''}
          placeholder={t('Search “Vienna, Austria”')}
          size="large"
          onChange={handleOnChange}
          onPressEnter={handleSearch}
          className="bg-light-cream hover:border-gh rounded-full"
        />
      </div>
      <FontAwesomeIcon
        icon={['fal', 'search']}
        className="cursor-pointer"
        onClick={handleSearch}
      />
    </>
  );
});

const MapAutoComplete = (props: { updateValue: any }): ReactElement => {
  const { updateValue } = props;
  return <SearchInput getInputValue={updateValue} />;
};

export default MapAutoComplete;
