import type { User } from 'types';

export interface State {
  user?: User;
}

export interface Action {
  user?: User;
  type: string;
}

// Action Types
export const SET_USER = 'SET_USER';
