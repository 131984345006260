import { getActiveLocale } from 'localizer';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import FooterIconPNG from '../../../assets/images/Footer-Icon.png';

const FooterIcon = (): ReactElement => {
  const location = useLocation();
  const locale = getActiveLocale(location.pathname);
  const openInNewTab = (url: any) => {
    window.open(url, '_blank');
    return false;
  };
  return (
    <div className="mt-1 mb-2 md:my-0">
      <a
        className="items-center"
        onClick={() =>
          openInNewTab(
            locale === 'en'
              ? 'https://www.co2neutralwebsite.de/certificate/7241/en'
              : 'https://www.co2neutralwebsite.de/certificate/7241/de',
          )
        }
        href="javascript:void(0);"
      >
        <img
          src={FooterIconPNG}
          alt="co2neutralwebsite"
          width="160"
          height="60"
        />
      </a>
    </div>
  );
};

export default FooterIcon;
