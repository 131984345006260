import {
  Action,
  CHANGE_COOKIE_PREF_VISIBILITY,
  CHANGE_SEARCH_VISIBILITY,
  SHOW_FRCH_POPUP,
  SHOW_NEWSLETTER_POPUP,
  State,
} from '../types/layout';

const initialState: State = {
  searchVisibility: false,
  cookiePrefVisibility: false,
  showNewsletterPopup: '',
  showFRCHPopup: '',
};

export default (state = initialState, action: Action): State => {
  switch (action.type) {
    case CHANGE_SEARCH_VISIBILITY:
      return {
        ...state,
        searchVisibility: action.searchVisibility,
      };
    case CHANGE_COOKIE_PREF_VISIBILITY:
      return {
        ...state,
        cookiePrefVisibility: action.cookiePrefVisibility,
      };
    case SHOW_NEWSLETTER_POPUP:
      return {
        ...state,
        showNewsletterPopup: action.showNewsletterPopup,
      };
    case SHOW_FRCH_POPUP:
      return {
        ...state,
        showFRCHPopup: action.showFRCHPopup,
      };
    default:
      return state;
  }
};
